import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useData } from '../../hooks/data';
import { FirebaseService } from '../../service/FirebaseService';
import { confirmDialog } from 'primereact/confirmdialog';
import { Timestamp } from 'firebase/firestore';
import { Toast } from 'primereact/toast';
import { deepCopy, getProjectFromDataUsingPathname } from '../../utils/utils';
import AppMenu from '../../components/Menu/AppMenu';
import ScoringView, { ScoringViewMode } from '../../components/ScoringView/ScoringView';
import { keys } from '../../utils/keys';

const AssessmentFormTab = () => {
  const history = useHistory();
  const toast = useRef(null);
  const data = useData();
  const [project, setProject] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [tabHeaders, setTabHeaders] = useState([]);
  const [renderCounter, setRenderCounter] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (history.location.state !== undefined) {
      setActiveTab(history.location.state);
    }
  }, [history]);

  useEffect(() => {
    const ref = getProjectFromDataUsingPathname(history.location.pathname, data);
    if (ref !== null) {
      const configured = configureProjectAndPanels(ref);
      setProject(deepCopy(configured.project));
      setTabHeaders(configured.panels);
    }
  }, [data]);

  const handleToast = (type, text) => {
    toast.current.show({ severity: type, summary: text, life: 3000 });
  };

  const configureProjectAndPanels = (ref) => {
    let newPanels = [];
    ref.areas.forEach((areaObj, areaIndex) => {
      newPanels.push({
        index: areaIndex,
        label: areaObj.name,
        isCompleted: areaObj.status === 'completed'
      });
      areaObj.indicators.map((indicatorObj) => {
        if (indicatorObj.evidence === undefined) {
          indicatorObj.evidence = '';
        }
        if (indicatorObj.notes === undefined) {
          indicatorObj.notes = '';
        }
      });
      areaObj.indicators.sort((a, b) => {
        return a.index < b.index ? -1 : 1;
      });
    });
    return { project: ref, panels: newPanels };
  };

  const statusCheck = () => {
    let newProject = project;
    newProject.areas.map((areaObj) => {
      let status = 'completed';
      let isEmpty = true;

      for (let i = 0; i < areaObj.indicators.length; i++) {
        const obj = areaObj.indicators[i];
        if (
          obj.evidence === undefined ||
          obj.evidence === '' ||
          obj.notes === undefined ||
          obj.notes === '' ||
          obj.score_value === 0
        ) {
          status = 'in_progress';
        }
        if (
          (obj.evidence !== undefined && obj.evidence !== '') ||
          (obj.notes !== undefined && obj.notes !== '') ||
          obj.score_value > 0
        ) {
          isEmpty = false;
        }
      }

      status = isEmpty ? 'open' : status;
      areaObj.status = status;
    });
    setProject(newProject);
  };

  const calculateProjectScoreValue = () => {
    let indicatorsCount = 0;
    let indicatorsValue = 0;

    project.areas.forEach((a) => {
      a.indicators.forEach((i) => {
        indicatorsCount++;
        indicatorsValue += i.score_value;
      });
    });

    return indicatorsValue / indicatorsCount;
  };

  const validateProjectStatus = (ref) => {
    let empty = true;
    let completed = true;

    ref.areas.map((a) => {
      let areaEmpty = true;
      let areaCompleted = true;

      a.indicators.forEach((i) => {
        if (i.score_value === 0) {
          completed = false;
          areaCompleted = false;
        } else if (i.score_value > 0) {
          empty = false;
          areaEmpty = false;
        }
      });

      a.status_code = areaEmpty ? 'open' : !completed ? 'in_progress' : 'completed';
    });

    ref.status = empty || !completed ? 'Pengisian Penilaian' : 'Selesai';
    ref.status_code = empty || !completed ? 'in_progress' : 'completed';

    return ref;
  };

  const saveProject = () => {
    const finalProject = validateProjectStatus(project);
    FirebaseService.setDocument(
      keys.PROJECTS_COLLECTION + '/' + project.id,
      {
        areas: finalProject.areas,
        score_value: calculateProjectScoreValue(),
        status: finalProject.status,
        status_code: finalProject.status_code,
        updated_at: Timestamp.now()
      },
      true
    ).then(
      () => {
        setIsLoading(false);
        handleToast('success', 'Assessment berhasil disimpan!');
        if (activeTab !== tabHeaders.length - 1) {
          setActiveTab(activeTab + 1);
        }
      },
      (err) => {
        setIsLoading(false);
        handleToast('error', 'Gagal menyimpan data assessment! ' + err.message);
      }
    );
  };

  const onTabChange = (e) => {
    setActiveTab(e.index);
  };

  const onInputChanged = (e, index) => {
    let indicator = project.areas[activeTab].indicators[index];

    if (e.target.name === 'evidence') {
      indicator.evidence = e.target.value;
    } else {
      indicator.notes = e.target.value;
    }

    let newProject = project;
    newProject.areas[activeTab].indicators[index] = indicator;

    if (!isChanged) {
      setIsChanged(true);
    }

    setProject(newProject);
    setRenderCounter(renderCounter + 1);
  };

  const onScoreSelection = (e, index) => {
    let totalScore = 0;
    let newProject = project;

    newProject.areas.map((areaObj, areaIndex) => {
      if (areaIndex === activeTab) {
        let areaScore = 0;
        areaObj.indicators.map((indicatorObj, indicatorIndex) => {
          if (indicatorIndex === index) {
            indicatorObj.score_value = e.value;
          }
          areaScore += indicatorObj.score_value;
        });
        areaObj.score_value = areaScore / areaObj.indicators.length;
        totalScore += areaObj.score_value;
      } else {
        totalScore += areaObj.score_value;
      }
    });

    newProject.score_value = totalScore / newProject.areas.length;

    if (!isChanged) {
      setIsChanged(true);
    }

    setProject(newProject);
    setRenderCounter(renderCounter + 1);
  };

  const onSelectIndicator = (value) => {
    let totalScore = 0;
    let newProject = project;

    newProject.areas.map((areaObj, areaIndex) => {
      if (areaIndex === activeTab) {
        let areaScore = 0;
        areaObj.indicators.map((indicatorObj) => {
          indicatorObj.score_value = value;
          areaScore += indicatorObj.score_value;
        });
        areaObj.score_value = areaScore / areaObj.indicators.length;
        totalScore += areaObj.score_value;
      } else {
        totalScore += areaObj.score_value;
      }
    });

    newProject.score_value = totalScore / newProject.areas.length;

    if (!isChanged) {
      setIsChanged(true);
    }

    setProject(newProject);
    setRenderCounter(renderCounter + 1);
  };

  const onGoBack = () => {
    if (isChanged) {
      confirmDialog({
        message: 'Apakah Anda yakin untuk meninggalkan halaman ini?',
        header: 'Data Belum Disimpan',
        acceptLabel: 'Ya',
        acceptIcon: 'pi pi-trash',
        acceptClassName: 'p-button-danger',
        rejectLabel: 'Tidak',
        rejectIcon: 'pi pi-times',
        accept: () => history.goBack()
      });
    } else {
      history.goBack();
    }
  };

  const onSubmit = () => {
    setIsLoading(true);
    statusCheck();
    saveProject();
  };

  return (
    <AppMenu>
      <ScoringView
        mode={ScoringViewMode.assessment}
        project={project}
        tabHeaders={tabHeaders}
        activeTab={activeTab}
        onTabChange={onTabChange}
        onScoreSelection={onScoreSelection}
        onInputChange={onInputChanged}
        onGoBack={onGoBack}
        onSubmit={onSubmit}
        onSelectIndicator={onSelectIndicator}
        isLoadingSubmission={isLoading}
        isSubmitted={
          project === null || project.is_submitted === undefined ? false : project.is_submitted
        }
      />
      <Toast ref={toast} />
    </AppMenu>
  );
};

export default AssessmentFormTab;
