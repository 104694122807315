import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useData } from '../../hooks/data';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { confirmDialog } from 'primereact/confirmdialog';
import { Row } from 'primereact/row';
import { Toast } from 'primereact/toast';
import AppMenu from '../../components/Menu/AppMenu';
import Table from '../../components/Table/Table';
import TableActionButton from '../../components/Table/TableActionButton';
import { FirebaseService } from '../../service/FirebaseService';
import { keys } from '../../utils/keys';

const Indicator = () => {
  const history = useHistory();
  const data = useData();
  const toast = useRef(null);
  const [indicators, setIndicators] = useState([]);
  const handleToast = (type, text) => {
    toast.current.show({ severity: type, summary: text, life: 3000 });
  };

  useEffect(() => {
    if (history.location.state === 'created') {
      handleToast('success', 'Berhasil menambahkan indikator!');
      window.scrollTo(0, document.body.scrollHeight);
    } else if (history.location.state === 'modified') {
      handleToast('success', 'Berhasil mengubah indikator!');
    }
  }, [history]);

  useEffect(() => {
    if (data.indicatorListenerError !== '') {
      handleToast('error', 'Gagal mengambil data-data Indicator. ', data.indicatorListenerError);
    }
    consolidateIndicators();
  }, [data]);

  const consolidateIndicators = () => {
    let newIndicators = data.indicators;
    newIndicators.map((obj) => {
      const parentArea = data.areas.filter((areaObj) => areaObj.id === obj.area_id);
      if (parentArea.length > 0) {
        obj.area_name = parentArea[0].name;
        obj.area_index = parentArea[0].index;
      }
    });

    // newIndicators.sort(
    //   (a, b) => a.area_index.localeCompare(b.area_index) || a.area_name.localeCompare(b.area_name)
    // );
    setIndicators(newIndicators);
  };

  const onAcceptDeletePopUp = (indicatorId) => {
    FirebaseService.deleteDocument(keys.INDICATORS_COLLECTION + '/' + indicatorId).then(
      () => {
        handleToast('success', 'Berhasil menghapus indikator!');
      },
      (err) => {
        handleToast('error', 'Gagal menghapus indikator! ' + err.message);
      }
    );
  };

  const handleDeletePopup = (indicatorId) => {
    confirmDialog({
      header: 'Hapus Indikator',
      message: 'Apakah Anda yakin akan menghapus indikator ini?',
      className: 'no-icon',
      acceptLabel: 'Ya',
      acceptIcon: 'pi pi-trash',
      acceptClassName: 'p-button-danger',
      rejectLabel: 'Tidak',
      rejectIcon: 'pi pi-times',
      rejectClassName: 'p-button-outlined p-button-secondary',
      accept: () => onAcceptDeletePopUp(indicatorId)
    });
  };

  const headerColumnGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Kode Area" rowSpan={2} align="center" style={{ width: 100 }} />
        <Column header="Area" rowSpan={2} align="center" style={{ width: 200 }} />
        <Column header="Kode Indikator" rowSpan={2} align="center" style={{ width: 100 }} />
        <Column header="Deskripsi Indikator" rowSpan={2} align="center" style={{ width: 250 }} />
        <Column header="Tingkat" colSpan={5} align="center" />
        <Column header="Action" rowSpan={2} align="center" style={{ width: 100 }} />
      </Row>
      <Row>
        <Column header="Initial" align="center" style={{ width: 250 }} />
        <Column header="Repeatable" align="center" style={{ width: 250 }} />
        <Column header="Defined" align="center" style={{ width: 250 }} />
        <Column header="Managed" align="center" style={{ width: 250 }} />
        <Column header="Optimized" align="center" style={{ width: 250 }} />
      </Row>
    </ColumnGroup>
  );

  const actionBody = (rowData) => (
    <div className="flex align-items-center">
      <TableActionButton
        color="warning"
        icon="pi pi-pencil"
        tooltipText="Edit Indikator"
        onClick={() => history.push(`/indicator/edit/${rowData.id}`, rowData.id)}
      />
      <TableActionButton
        color="danger"
        icon="pi pi-trash"
        tooltipText="Hapus Indikator"
        onClick={() => handleDeletePopup(rowData.id)}
      />
    </div>
  );

  const tdStyle = { flex: 'auto', alignItems: 'flex-start' };

  return (
    <AppMenu>
      <div className="card">
        <div className="flex align-items-center justify-content-between mb-4">
          <h4 className="font-bold mb-0">Indikator</h4>
          <Button
            label="Tambah Indikator"
            icon="pi pi-plus"
            onClick={() => history.push('/indicator/create')}
          />
        </div>
        <Table
          headerColumnGroup={headerColumnGroup}
          data={indicators}
          loading={data.indicatorIsLoading}
          totalRows={50}>
          <Column field="area_index" style={{ width: 100, ...tdStyle }} />
          <Column field="area_name" style={{ width: 200, ...tdStyle }} />
          <Column field="index" style={{ width: 100, ...tdStyle }} />
          <Column field="description" style={{ width: 250, ...tdStyle }} />
          <Column field="scores.0.description" style={{ width: 250, ...tdStyle }} />
          <Column field="scores.1.description" style={{ width: 250, ...tdStyle }} />
          <Column field="scores.2.description" style={{ width: 250, ...tdStyle }} />
          <Column field="scores.3.description" style={{ width: 250, ...tdStyle }} />
          <Column field="scores.4.description" style={{ width: 250, ...tdStyle }} />
          <Column body={actionBody} style={{ width: 100, ...tdStyle }} />
        </Table>
      </div>

      <Toast ref={toast} style={{ zIndex: 999 }} />
    </AppMenu>
  );
};

export default Indicator;