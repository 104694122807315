import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useData } from '../../hooks/data';
import { getProjectFromDataUsingPathname } from '../../utils/utils';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import AppMenu from '../../components/Menu/AppMenu';
import Table from '../../components/Table/Table';
import TableActionButton from '../../components/Table/TableActionButton';

const Target = () => {
  const history = useHistory();
  const data = useData();
  const [project, setProject] = useState(null);

  useEffect(() => {
    const ref = getProjectFromDataUsingPathname(history.location.pathname, data);
    if (ref !== null) {
      setProject(ref);
    }
  }, [data]);

  const getTargetStatus = (area) => {
    let status = 'success';
    let isEmpty = true;

    area.indicators.map((obj) => {
      if (obj.target_value < 1) {
        status = 'warning';
      } else {
        isEmpty = false;
      }
    });

    return isEmpty ? 'info' : status;
  };

  const goToTargetForm = (areaId) => {
    const index = project.areas.findIndex((obj) => obj.id === areaId);
    if (index !== -1) {
      history.push(`${history.location.pathname}/edit`, index);
    }
  };

  const parseNumberValue = (value) => {
    if (value > 0) {
      return value.toFixed(2);
    }
    return value;
  };

  const statusBody = (rowData) => {
    const status = getTargetStatus(rowData);
    return (
      <Tag
        className="uppercase px-2"
        severity={status}
        value={status === 'success' ? 'Selesai' : status === 'warning' ? 'In Progress' : 'Open'}
        style={{ letterSpacing: 0.3, fontSize: 12 }}
      />
    );
  };

  const actionBody = (rowData) => (
    <TableActionButton
      color="warning"
      icon="pi pi-pencil"
      tooltipText="Edit"
      onClick={() => goToTargetForm(rowData.id)}
    />
  );

  return (
    <AppMenu>
      <div className="card">
        <div className="flex align-items-center justify-content-between mb-4">
          <h3 className="font-bold mb-0">Target</h3>
          <div className="flex align-items-center">
            {project && (
              <div className="flex align-items-baseline justify-content-end">
                <h6 className="mr-5 mb-0">Total Target Tingkat Maturitas: </h6>
                <b className="text-blue-800 text-3xl">{project.target_value.toFixed(2)}</b>
              </div>
            )}
          </div>
        </div>
        <Table data={project?.areas || []} loading={data.areaIsLoading} totalRows={20}>
          <Column field="index" header="Kode Area" style={{ minWidth: '10%' }} />
          <Column field="name" header="Area" style={{ minWidth: '40%' }} />
          <Column
            field="target_value"
            header="Target Tingkat Maturitas"
            body={(rowData) => parseNumberValue(rowData.target_value)}
            style={{ minWidth: '20%' }}
          />
          <Column
            field="status"
            header="Status"
            body={(rowData) => statusBody(rowData)}
            style={{ minWidth: '20%' }}
          />
          <Column header="Action" body={actionBody} style={{ minWidth: '10%' }} />
        </Table>
      </div>
    </AppMenu>
  );
};

export default Target;
