import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FirebaseService } from '../../service/FirebaseService';
import { Timestamp } from 'firebase/firestore';
import { keys } from '../../utils/keys';
import { getApp, initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { setAccessToken } from '../../utils/creds';
import { getUserFromData} from '../../utils/utils';

FirebaseService.initFirebase();

const ProjectForm = (props) => {
  const { areas, indicators, show, setShow, setSuccess, setFailed } = props;
  const [index, setIndex] = useState('');
  const [name, setName] = useState('');
  const [period, setPeriod] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const auth = getAuth();


  let year = new Date().getFullYear();

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === 'index') {
      setIndex(value);
    } else if (name === 'name') {
      setName(value);
    } else if (name === 'period') {
      setPeriod(value);
    }
  };

  const handleClose = () => {
    setIndex('');
    setName('');
    setPeriod('');
    setShow();
  };

  const populateIndicators = (areaId) => {
    let result = indicators.filter((obj) => obj.area_id === areaId);
    result.map((obj) => {
      obj.score_value = 0;
      obj.target_value = 0;
    });
    return result;
  };

  const populateAreas = () => {
    let result = areas;
    result.sort((a, b) => a.created_at - b.created_at);
    result.map((obj) => {
      const indicators = populateIndicators(obj.id);
      obj.indicators = indicators;
      obj.indicator_count = indicators.length;
      obj.score_value = 0;
      obj.target_value = 0;
      obj.status = 'open';
    });
    result = result.filter((obj) => {
      return obj.indicators.length > 0;
    });
    return result;
  };

  const handleSubmit = () => {
    onAuthStateChanged(auth, (user)=> {
      if(user){
        setIsLoading(true);
        const areas = populateAreas();
        const now = Timestamp.now();
        FirebaseService.addDocument(keys.PROJECTS_COLLECTION, {
          area_count: areas.length,
          areas: areas,
          code: index,
          created_at: now,
          entity: name,
          period: period,
          score_value: 0,
          status: 'Pengisian Target',
          status_code: 'open',
          target_value: 0,
          is_submitted: false,
          updated_at: now,
          createdBy : user.email
        }).then(
          () => {
            setIsLoading(false);
            handleClose();
            setSuccess();
          },
          (err) => {
            setIsLoading(false);
            handleClose();
            setFailed('Gagal menambah project baru! ' + err.message);
          }
        );
      }

    });
    
  };

  const navigatorTemplate = (e) => (
    <Dropdown
      value={e.value}
      options={e.options}
      onChange={(event) => e.onChange(event.originalEvent, event.value)}
      className="mx-1"
      style={{ lineHeight: 1 }}
    />
  );

  const footer = (
    <div className="flex align-items-center justify-content-end">
      <Button
        type="button"
        label="Batal"
        onClick={handleClose}
        icon="pi pi-times"
        className="p-button-warning"
      />
      <Button
        type="submit"
        label="Simpan"
        icon="pi pi-save"
        loading={isLoading}
        onClick={handleSubmit}
        disabled={!index || !name || !period}
      />
    </div>
  );

  return (
    <Dialog
      header="Tambah Engagement Baru"
      visible={show}
      breakpoints={{ '960px': '50vw', '640px': '100vw' }}
      style={{ width: '30vw' }}
      modal
      footer={footer}
      onHide={handleClose}>
      <label htmlFor="index" className="block font-medium mb-2">
        Kode Proyek
      </label>
      <InputText
        type="text"
        name="index"
        id="index"
        value={index}
        placeholder="Masukkan kode proyek"
        className="w-full mb-3"
        onChange={handleChangeInput}
        autoFocus
      />
      <label htmlFor="name" className="block font-medium mb-2">
        Entitas
      </label>
      <InputText
        type="text"
        name="name"
        id="name"
        value={name}
        placeholder="Masukkan entitas"
        className="w-full mb-3"
        onChange={handleChangeInput}
      />
      <label htmlFor="period" className="block font-medium mb-2">
        Periode Penilaian
      </label>
      <Calendar
        showIcon
        name="period"
        id="period"
        value={period}
        placeholder="Pilih tanggal periode penilaian"
        className="w-full mb-3"
        onChange={handleChangeInput}
        dateFormat="dd/mm/yy"
        monthNavigator
        yearNavigator
        yearRange={`${year - 10}:${year + 10}`}
        monthNavigatorTemplate={navigatorTemplate}
        yearNavigatorTemplate={navigatorTemplate}
      />
    </Dialog>
  );
};

export default ProjectForm;
