import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { TabPanel, TabView } from 'primereact/tabview';
import ScoringForm from './ScoringForm';
import { confirmDialog } from 'primereact/confirmdialog';

export const ScoringViewMode = {
  target: 'target',
  assessment: 'assessment'
};

const ScoringView = (props) => {
  const {
    mode,
    project,
    tabHeaders,
    activeTab,
    onTabChange,
    onScoreSelection,
    onInputChange,
    onGoBack,
    onSubmit,
    onSelectIndicator,
    isLoadingSubmission,
    isSubmitted
  } = props;

  const [selectedIndicator, setSelectedIndicator] = useState(null);

  useEffect(() => {
    if (project) {
      checkForSameValue();
    }
  }, [activeTab, project]);

  const checkForSameValue = () => {
    const arrIndicator = project.areas[activeTab].indicators;
    const isEqual = arrIndicator.every((anIndicator) => {
      if (mode === ScoringViewMode.target) {
        return anIndicator.target_value === arrIndicator[0].target_value;
      }
      return anIndicator.score_value === arrIndicator[0].score_value;
    });
    setSelectedIndicator(
      !isEqual
        ? null
        : mode === ScoringViewMode.target
        ? arrIndicator[0].target_value
        : arrIndicator[0].score_value
    );
  };

  const setAllIndicatorsValue = (e) => {
    confirmDialog({
      message: 'Apakah Anda yakin untuk mengubah seluruh nilai indikator?',
      header: 'Konfirmasi',
      acceptLabel: 'Ya',
      acceptIcon: 'pi pi-check',
      acceptClassName: 'p-button-success',
      rejectLabel: 'Tidak',
      rejectIcon: 'pi pi-times',
      accept: () => {
        setSelectedIndicator(e.target.value);
        onSelectIndicator(e.target.value);
      }
    });
  };

  const indicatorValueSelectionHandler = (e, index) => {
    onScoreSelection(e, index);
    checkForSameValue();
  };

  const indicatorOpt = [
    { label: '1 - Initial', value: 1 },
    { label: '2 - Repeatable', value: 2 },
    { label: '3 - Defined', value: 3 },
    { label: '4 - Managed', value: 4 },
    { label: '5 - Optimized', value: 5 }
  ];

  const renderHeaderLabel = (value) => {
    let { label } = value;
    if (value.isCompleted) {
      label += ' *';
    }
    return label;
  };

  const renderScoreCard = (title, value, shouldGrey) => (
    <div className="col-12 md:col-3">
      <div className={classNames('form-card-border assessment-box', { 'surface-300': shouldGrey })}>
        <span className="text-sm">{title}</span>
        <h2 className="mt-1 mb-0 text-yellow-400">{value}</h2>
      </div>
    </div>
  );

  return (
    <div className="card">
      <TabView
        activeIndex={activeTab}
        onTabChange={onTabChange}
        scrollable
        className="tabview-custom">
        {tabHeaders.map((val, key) => (
          <TabPanel header={renderHeaderLabel(val)} key={key}>
            <div className="grid justify-content-between py-3 mt-0">
              <div className="col-12 lg:col-8 grid">
                {renderScoreCard(
                  'Target Tingkat Maturitas',
                  project.target_value.toFixed(2),
                  mode !== ScoringViewMode.target
                )}
                {renderScoreCard(
                  'Target Tingkat Maturitas Per Area',
                  project?.areas[key].target_value > 0
                    ? project.areas[key].target_value.toFixed(2)
                    : 0,
                  mode !== ScoringViewMode.target
                )}
                {mode !== ScoringViewMode.target &&
                  renderScoreCard(
                    'Hasil Penilaian Tingkat Maturitas',
                    project?.score_value > 0 ? project.score_value.toFixed(2) : 0,
                    false
                  )}
                {mode !== ScoringViewMode.target &&
                  renderScoreCard(
                    'Hasil Penilaian Tingkat Maturitas Per Area',
                    project?.areas[key].score_value > 0
                      ? project.areas[key].score_value.toFixed(2)
                      : 0,
                    false
                  )}
              </div>
              <div className="col-12 md:col-3">
                <label htmlFor="indicator" className="block font-medium mb-2">
                  Nilai Seluruh Indikator
                </label>
                <Dropdown
                  name="indicator"
                  id="indicator"
                  value={selectedIndicator}
                  options={indicatorOpt}
                  placeholder="Pilih Nilai"
                  className="w-full"
                  onChange={setAllIndicatorsValue}
                  disabled={isSubmitted}
                />
              </div>
            </div>
            <ScoringForm
              mode={mode}
              area={project.areas[activeTab]}
              onInputChange={(e, index) => onInputChange && onInputChange(e, index)}
              onScoreSelection={indicatorValueSelectionHandler}
              onGoBack={onGoBack}
              onSubmit={onSubmit}
              isLoadingSubmission={isLoadingSubmission}
              isSubmitted={isSubmitted}
            />
          </TabPanel>
        ))}
      </TabView>
    </div>
  );
};

export default ScoringView;
