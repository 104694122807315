import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { menuAdmin, menuClient } from '../../routes';
import { Navbar } from './Navbar';
import { Sidebar } from './Sidebar';
import { removeAccessToken } from '../../utils/creds';
import PrimeReact from 'primereact/api';
import { useData } from '../../hooks/data';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '../../assets/styles/layout.scss';
import { FirebaseService } from '../../service/FirebaseService';
import { getApp, initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { setAccessToken } from '../../utils/creds';
import { getUserFromData} from '../../utils/utils';

FirebaseService.initFirebase();

const AppMenu = (props) => {
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const copyTooltipRef = useRef();
  const location = useLocation();
  const [role, setRole] = useState([]);
  const [email, setEmail] = useState([]);

  const data = useData();
  const auth = getAuth();

  PrimeReact.ripple = true;

  const isDesktop = window.innerWidth >= 992;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  // useEffect(() => {
    
    
  // }, [data]);

  useEffect(() => {
    if (data.userListenerError !== '') {
      
    } else if (data.projects.length > 0) {
      onAuthStateChanged(auth, (user)=> {
        if(user){
          const emailUser = user.email;
          let userRef = data.user.filter((userObj) => userObj.email === user.email);
          userRef.map((userObj) => {
            const roles = userObj.role;
            setRole(roles);
          })
        }

      });
    }
  }, [data]);

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, 'body-overflow-hidden');
    } else {
      removeClass(document.body, 'body-overflow-hidden');
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onWrapperClick = () => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop) {
      if (mobileMenuActive === true) {
        setOverlayMenuActive(true);
      }

      setOverlayMenuActive((prevState) => !prevState);
      setMobileMenuActive(false);
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;
    event.preventDefault();
    removeAccessToken();
    setTimeout(() => {
      window.location.replace('/login');
    }, 100);
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += ' ' + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      );
  };

  const wrapperClass = classNames('layout-wrapper', 'layout-overlay', 'layout-theme-light', {
    'layout-overlay-sidebar-active': overlayMenuActive,
    'layout-mobile-sidebar-active': mobileMenuActive
  });

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <Navbar
        onToggleMenuClick={onToggleMenuClick}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      <div className="layout-sidebar" onClick={onSidebarClick}>
        {role === "Admin" ? (
          <Sidebar model={menuAdmin} onMenuItemClick={onMenuItemClick} layoutColorMode="light" />
        ) : (
          <Sidebar model={menuClient} onMenuItemClick={onMenuItemClick} layoutColorMode="light" />
        )}
        
      </div>

      <div className="layout-main-container layout-padding">
        <div className="layout-main">{props.children}</div>
      </div>

      <CSSTransition
        classNames="layout-mask"
        timeout={{ enter: 200, exit: 200 }}
        in={mobileMenuActive}
        unmountOnExit>
        <div className="layout-mask p-component-overlay" />
      </CSSTransition>
    </div>
  );
};

export default AppMenu;
