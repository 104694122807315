import React, { useState, useEffect } from 'react';
import { useData } from '../../hooks/data';
import { useHistory } from 'react-router-dom';
import AppMenu from '../../components/Menu/AppMenu';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Medal1Image from '../../assets/images/medal-1.png';
import Medal2Image from '../../assets/images/medal-2.png';
import Medal3Image from '../../assets/images/medal-3.png';
import { Image } from 'primereact/image';
import { Timestamp } from 'firebase/firestore';
import { deepCopy } from '../../utils/utils';
import { FirebaseService } from '../../service/FirebaseService';
import { getApp, initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { setAccessToken } from '../../utils/creds';
import { getUserFromData} from '../../utils/utils';

FirebaseService.initFirebase();

const lineData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'First Dataset',
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: false,
      backgroundColor: '#2f4860',
      borderColor: '#2f4860',
      tension: 0.4
    },
    {
      label: 'Second Dataset',
      data: [28, 48, 40, 19, 86, 27, 90],
      fill: false,
      backgroundColor: '#00bb7e',
      borderColor: '#00bb7e',
      tension: 0.4
    }
  ]
};

const Dashboard = (props) => {
  const history = useHistory();
  const data = useData();
  const auth = getAuth();

  const [projects, setProjects] = useState([]);
  const [completedProjects, setCompletedProjects] = useState([]);
  const [role, setRole] = useState([]);
  const [averageScoreValue, setAverageScoreValue] = useState(null);
  const [projectProgressData, setProjectProgressData] = useState(null);
  const [valueOverviewData, setValueOverviewData] = useState(null);

  useEffect(() => {
    const refs = data.projects;
    onAuthStateChanged(auth, (user)=> {
      if(user){
        const email = user.email;

        const userRef = data.user.filter((userObj) => userObj.email === email);
        userRef.map((userObj) => {
          const roles = userObj.role;
          setRole(roles);
          if(userObj.role === 'Admin'){
            let completed = refs.filter((p) => p.status_code === 'completed');

            let averageScore = 0;

            completed.map((p) => {
              p.resolve_time = Math.ceil((p.updated_at.seconds - p.created_at.seconds) / 86400);
              averageScore += p.score_value;
            });
            averageScore /= completed.length;

            setProjects(refs.length === 0 ? [] : refs.sort((a, b) => b.created_at - a.created_at));
            setCompletedProjects(completed.sort((a, b) => b.updated_at - a.updated_at));
            setProjectProgressData(processProjectProgressData(refs));
            setValueOverviewData(processTargetOverviewData(completed));
            setAverageScoreValue(averageScore);
          }else{
            let ref_user = refs.filter((p) => p.createdBy === email);
            let completed = ref_user.filter((p) => p.status_code === 'completed');

            let averageScore = 0;

            completed.map((p) => {
              p.resolve_time = Math.ceil((p.updated_at.seconds - p.created_at.seconds) / 86400);
              averageScore += p.score_value;
            });
            averageScore /= completed.length;

            setProjects(ref_user.length === 0 ? [] : ref_user.sort((a, b) => b.created_at - a.created_at));

            setCompletedProjects(completed.sort((a, b) => b.updated_at - a.updated_at));
            setProjectProgressData(processProjectProgressData(refs));
            setValueOverviewData(processTargetOverviewData(completed));
            setAverageScoreValue(averageScore);
          }
          
        });
      }

    });
    
  }, [data]);

  const calculateAverageAssessmentTime = () => {
    let res = 0;
    completedProjects.forEach((p) => {
      res += p.resolve_time;
    });
    return Math.ceil(res / completedProjects.length);
  };

  const processProjectProgressData = (arrProjects) => {
    let progressData = {
      open: 0,
      inProgress: 0,
      completed: 0
    };

    arrProjects.forEach((p) => {
      if (p.status_code === 'open') {
        progressData.open++;
      } else if (p.status_code === 'completed') {
        progressData.completed++;
      } else {
        progressData.inProgress++;
      }
    });

    return {
      labels: ['Open', 'In Progress', 'Completed'],
      datasets: [
        {
          label: ' Total',
          data: [progressData.open, progressData.inProgress, progressData.completed],
          backgroundColor: ['#fe7d5e', '#ffb850', '#4561aa']
        }
      ]
    };
  };

  const processTargetOverviewData = (arrCompleted) => {
    let overviewData = {
      underTarget: 0,
      onTarget: 0,
      overTarget: 0
    };

    arrCompleted.forEach((p) => {
      if (p.score_value === p.target_value) {
        overviewData.onTarget++;
      } else if (p.score_value > p.target_value) {
        overviewData.overTarget++;
      } else {
        overviewData.underTarget++;
      }
    });

    return {
      labels: [' Under Target', ' On Target', ' Over Target'],
      datasets: [
        {
          label: 'default',
          data: [overviewData.underTarget, overviewData.onTarget, overviewData.overTarget],
          backgroundColor: ['#fe7d5e', '#ffb850', '#4561aa'],
          hoverOffset: 2
        }
      ]
    };
  };

  const onProjectSelected = (data) => {
    const code = data.status_code;
    const status = data.status;

    if (code === 'completed') {
      history.push(`/project/${data.id}/assessment/dashboard`);
    } else if (code === 'open' || (code === 'in_progress' && status === 'Pengisian Target')) {
      history.push(`/project/${data.id}/target`);
    } else {
      history.push(`/project/${data.id}/assessment`);
    }
  };

  const renderProjectTerbaru = () => {
    let refs = deepCopy(projects);

    if (refs.length < 6) {
      const now = Timestamp.fromMillis(0);
      let dummyValues = [];
      for (let i = 6; i > refs.length; i--) {
        dummyValues.push({
          entity: '',
          target_value: -1,
          score_value: -1,
          created_at: now,
          updated_at: now
        });
      }
      refs.push(...dummyValues);
    }

    return (
      <div className="card">
        <h5>Summary Hasil Penilaian Per Engagement</h5>
        <DataTable value={refs} rows={6} paginator responsiveLayout="scroll">
          <Column field="entity" header="Engagement" sortable style={{ width: '35%' }} />
          <Column
            field="target"
            header="Target Maturitas"
            style={{ width: '35%' }}
            body={(data) => data.target_value !== -1 && data.target_value.toFixed(2)}
          />
          <Column
            field="Nilai"
            header="Nilai Maturitas"
            style={{ width: '35%' }}
            body={(data) => data.score_value !== -1 && data.score_value.toFixed(2)}
          />
          <Column
            header="Detail"
            style={{ width: '15%' }}
            body={(data) => (
              <>
                <Button
                  icon="pi pi-search"
                  type="button"
                  onClick={() => onProjectSelected(data)}
                  className="p-button-text"
                  style={{
                    visibility: data.target_value < 0 || data.score_value < 0 ? 'hidden' : 'visible'
                  }}
                />
              </>
            )}
          />
        </DataTable>
      </div>
    );
  };

  const renderMVPResolveTime = (
    <div className="card">
      <h5>Penyelesaian Engagement Tercepat</h5>
      <DataTable
        value={completedProjects
          .sort((a, b) => {
            const aTime = a.updated_at.seconds - a.created_at.seconds;
            const bTime = b.updated_at.seconds - b.created_at.seconds;
            return aTime - bTime;
          })
          .slice(0, 3)}
        rows={3}
        responsiveLayout="scroll">
        <Column
          header="Peringkat"
          body={(_, row) => {
            return (
              <div className="flex align-items-center justify-content-center">
                <Image
                  src={
                    row.rowIndex === 0
                      ? Medal1Image
                      : row.rowIndex === 1
                      ? Medal2Image
                      : Medal3Image
                  }
                  width={30}
                  height={30}
                />
              </div>
            );
          }}
          style={{ width: '25%' }}
        />
        <Column field="entity" header="Engagement" style={{ width: '40%' }} />
        <Column
          field="resolve_time"
          header="Waktu Penyelesaian (hari)"
          body={(data) => {
            return (
              <div className="flex align-items-center justify-content-center">
                {data.resolve_time}
              </div>
            );
          }}
          style={{ width: '35%' }}
        />
      </DataTable>
    </div>
  );

  const renderProjectResolveTime = (
    <div className="card">
      <h5>Waktu Pengerjaan Penilaian</h5>
      <DataTable value={completedProjects} rows={5} paginator responsiveLayout="scroll">
        <Column field="entity" header="Engagement" sortable style={{ width: '35%' }} />
        <Column field="resolve_time" header="Waktu Penyelesaian (hari)" style={{ width: '35%' }} />
        <Column
          header="Detail"
          style={{ width: '15%' }}
          body={(data) => (
            <>
              <Button
                icon="pi pi-search"
                type="button"
                onClick={() => onProjectSelected(data)}
                className="p-button-text"
              />
            </>
          )}
        />
      </DataTable>
    </div>
  );

  const renderProjectProgressData = () => (
    <div className="card">
      <h5>Status Pelaksanaan Penilaian</h5>
      <Chart
        type="bar"
        data={projectProgressData}
        options={{
          plugins: { legend: { display: false } },
          scales: {
            x: { grid: { display: false } },
            y: {
              min: 0,
              max: 5,
              ticks: {
                stepSize: 0.5
              }
            }
          }
        }}
      />
    </div>
  );

  const renderTargetOverviewChart = () => (
    <div className="card">
      <h5>Summary Gap Hasil Penilaian</h5>
      <Chart type="pie" data={valueOverviewData} />
    </div>
  );

  const renderAverageScore = () => {
    if (averageScoreValue === null) {
      return '0';
    } else {
      return averageScoreValue.toFixed(2);
    }
  };

  return (
    <AppMenu>
      <h3 className="font-semibold mb-2">Dashboard</h3>
      <p>Summary Pelaksanaan Penilaian Risk Maturity</p>
      <div className="grid mt-3 mx-0">
        <div className="col-12 card" style={{ backgroundColor: '#F8F8F8' }}>
          <div className="flex flex-wrap">
            <div className="col-12 lg:col-6 xl:col-6">
              <div className="card mb-0">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-500 font-medium mb-3">
                      Total Pelaksanaan Penilaian
                    </span>
                    <div className="text-900 font-medium text-xl">{projects.length} Engagement</div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center bg-blue-100 border-round"
                    style={{ width: '2.5rem', height: '2.5rem' }}>
                    <i className="pi pi-briefcase text-blue-500 text-xl" />
                  </div>
                </div>
                <span className="text-500">&nbsp;</span>
              </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-6">
              <div className="card mb-0">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-500 font-medium mb-3">
                      Rata-Rata Nilai Hasil Penilaian Risk Maturity
                    </span>
                    <div className="text-900 font-medium text-xl">
                      {averageScoreValue === null ? 0 : averageScoreValue.toFixed(2)}
                    </div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center bg-blue-100 border-round"
                    style={{ width: '2.5rem', height: '2.5rem' }}>
                    <i className="pi pi-briefcase text-blue-500 text-xl" />
                  </div>
                </div>
                <span className="text-green-500 font-medium">&nbsp;</span>
                <span className="text-500" />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="col-12 lg:col-6 xl:col-3">
              {projectProgressData === null ? null : renderProjectProgressData()}
              {valueOverviewData === null ? null : renderTargetOverviewChart()}
            </div>
            <div className="col-12 lg:col-6 xl:col-9">
              {projects === [] ? null : renderProjectTerbaru()}
            </div>
          </div>
        </div>

        {role === "Admin" ? (
          <div className="col-12 card flex" style={{ backgroundColor: '#F8F8F8' }}>
            <div className="col-12 lg:col-6 xl:col-4">
              <div className="card">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-500 font-medium mb-3">
                      Rata-Rata Waktu Pelaksanaan Penilaian
                    </span>
                    <div className="text-900 font-medium text-xl">
                      {completedProjects.length < 1 ? '0' : calculateAverageAssessmentTime()} hari
                    </div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center bg-green-100 border-round"
                    style={{ width: '2.5rem', height: '2.5rem' }}>
                    <i className="pi pi-history text-green-500 text-xl" />
                  </div>
                </div>
                <span>&nbsp;</span>
              </div>
              {renderMVPResolveTime}
            </div>
            <div className="col-12 lg:col-6 xl:col-8">
              {projects === [] ? null : renderProjectResolveTime}
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    </AppMenu>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode
  );
};

export default React.memo(Dashboard, comparisonFn);
