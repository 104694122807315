import React, { useEffect } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton } from 'primereact/selectbutton';
import { ScoringViewMode } from './ScoringView';

const ScoringForm = (props) => {
  const {
    mode,
    area,
    onInputChange,
    onScoreSelection,
    onGoBack,
    onSubmit,
    isLoadingSubmission,
    isSubmitted
  } = props;

  const populateScoreOptions = (indicator) => {
    let result = [];
    indicator.scores.forEach((obj) => {
      result.push({
        label: obj.description,
        value: obj.value
      });
    });
    return result;
  };

  const renderAccordionTab = (obj, index) => (
    <AccordionTab
      key={'AT-T-' + index}
      header={`${obj.index} - ${obj.description}`}
      headerClassName="primary-accordion mt-3">
      <div className="grid">
        <div className="col-12 md:col-2 mb-2">
          <div className="flex mt-0 md:mt-2">
            <p className="w-4 lg:w-7 md:w-8 mb-0">Target</p>
            <p className="mb-0">{obj.target_value}</p>
          </div>
        </div>
        {mode !== ScoringViewMode.target && (
          <div className="col-12 md:col-5 mb-2">
            <div className="md:flex">
              <label htmlFor="evidence" className="block w-2 my-2">
                Evidence
              </label>
              <InputText
                type="text"
                name="evidence"
                value={obj.evidence}
                placeholder="Masukkan link yang merefer ke dokumen yang dijadikan sebagai evidence"
                className="w-full md:w-9"
                onChange={(e) => onInputChange(e, index)}
                disabled={isSubmitted}
              />
            </div>
          </div>
        )}
        {mode !== ScoringViewMode.target && (
          <div className="col-12 md:col-5">
            <div className="md:flex">
              <label htmlFor="notes" className="block w-2 my-2">
                Notes
              </label>
              <InputTextarea
                name="notes"
                value={obj.notes}
                placeholder="Tambahkan notes"
                rows={3}
                className="w-full"
                onChange={(e) => onInputChange && onInputChange(e, index)}
                disabled={isSubmitted}
              />
            </div>
          </div>
        )}
      </div>
      <div className="form-card mt-5">
        <div className="grid m-0">
          <div className="score-wrapper">
            <div className="form-card-border">
              <p className="text-center font-semibold">1 - Initial</p>
            </div>
          </div>
          <div className="score-wrapper">
            <div className="form-card-border">
              <p className="text-center font-semibold">2 - Repeatable</p>
            </div>
          </div>
          <div className="score-wrapper">
            <div className="form-card-border">
              <p className="text-center font-semibold">3 - Defined</p>
            </div>
          </div>
          <div className="score-wrapper">
            <div className="form-card-border">
              <p className="text-center font-semibold">4 - Managed</p>
            </div>
          </div>
          <div className="score-wrapper">
            <div className="form-card-border">
              <p className="text-center font-semibold">5 - Optimized</p>
            </div>
          </div>
        </div>
        <Divider />
        <SelectButton
          value={mode === ScoringViewMode.target ? obj.target_value : obj.score_value}
          options={populateScoreOptions(obj)}
          optionLabel="label"
          className="score-options"
          onChange={(e) => e.value && onScoreSelection(e, index)}
          disabled={isSubmitted}
        />
      </div>
    </AccordionTab>
  );

  return (
    <>
      <Accordion multiple activeIndex={area?.indicators.map((_, index) => index) || []}>
        {area.indicators.map((obj, index) => renderAccordionTab(obj, index))}
      </Accordion>
      <div className="flex align-items-center justify-content-end mt-5">
        <Button
          className="p-button-outlined mr-3"
          type="button"
          label="Kembali"
          icon="pi pi-arrow-left"
          onClick={onGoBack}
        />
        <Button
          type="submit"
          label="Simpan"
          icon="pi pi-save"
          loading={isLoadingSubmission}
          onClick={onSubmit}
        />
      </div>
    </>
  );
};

export default ScoringForm;
