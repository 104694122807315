import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Chart } from 'primereact/chart';
import { Column } from 'primereact/column';
import { Panel } from 'primereact/panel';
import { useData } from '../../hooks/data';
import { getProjectFromDataUsingPathname } from '../../utils/utils';
import AppMenu from '../../components/Menu/AppMenu';
import Table from '../../components/Table/Table';

const AssessmentDashboard = () => {
  const history = useHistory();
  const data = useData();

  const [project, setProject] = useState(null);

  useEffect(() => {
    const ref = getProjectFromDataUsingPathname(history.location.pathname, data);
    if (ref !== null) {
      setProject(ref);
    }
  }, [data]);

  const chartData = {
    labels: project ? project.areas.map((val) => val.index) : [],
    datasets: [
      {
        label: 'Target Tingkat Maturitas',
        backgroundColor: 'rgba(44, 60, 94, 0.2)',
        borderColor: 'rgb(44, 60, 94)',
        pointBackgroundColor: 'rgb(44, 60, 94)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(44, 60, 94)',
        data: project ? project.areas.map((val) => val.target_value) : []
      },
      {
        label: 'Tingkat Maturitas',
        backgroundColor: 'rgba(253, 162, 51, 0.2)',
        borderColor: 'rgb(253, 162, 51)',
        pointBackgroundColor: 'rgb(253, 162, 51)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(253, 162, 51)',
        data: project ? project.areas.map((val) => val.score_value) : []
      }
    ]
  };

  const headerTemplate = (text) => (
    <div className="bg-primary border-round-top text-white p-2 md:p-3">
      <p className="font-bold line-height-3 text-center">{text}</p>
    </div>
  );

  const panelBody = (header, value) => (
    <Panel
      headerTemplate={headerTemplate(header)}
      className="border-round w-full md:w-6 shadow-3 md:ml-3 mb-3">
      <h1 className="font-bold text-center">{value > 0 ? value.toFixed(2) : value}</h1>
    </Panel>
  );

  const targetBody = (rowData) => (
    <>{rowData.target_value > 0 ? rowData.target_value.toFixed(2) : rowData.target_value}</>
  );

  const scoreBody = (rowData) => (
    <>{rowData.score_value > 0 ? rowData.score_value.toFixed(2) : rowData.score_value}</>
  );

  const gapBody = (rowData) => (
    <>{Math.abs(rowData.target_value - rowData.score_value).toFixed(2)}</>
  );

  return (
    <AppMenu>
      <h3 className="font-semibold mb-2">Dashboard</h3>
      <p>Summary Penilaian Risk Maturity</p>
      <div className="card" style={{ backgroundColor: '#F8F8F8' }}>
        <div className="grid">
          <div className="col-12 lg:col-5 p-0 md:p-2 mb-5">
            <div className="card">
              <Chart type="radar" data={chartData} />
            </div>
          </div>
          <div className="col-12 lg:col-7">
            <div className="md:flex">
              {panelBody('Target Tingkat Maturitas Manajemen Resiko', project?.target_value || 0)}
              {panelBody('Tingkat Maturitas Manajemen Resiko', project?.score_value || 0)}
            </div>
            <div className="card md:ml-3">
              <Table
                data={project?.areas || []}
                loading={data.areaIsLoading}
                totalRows={5}
                hideGrid
                striped>
                <Column field="index" header="Kode Area" style={{ minWidth: '15%' }} />
                <Column field="name" header="Area" style={{ minWidth: '40%' }} />
                <Column
                  field="target_value"
                  header="Target Tingkat Maturitas"
                  body={targetBody}
                  style={{ minWidth: '20%' }}
                />
                <Column
                  field="score_value"
                  header="Tingkat Maturitas"
                  body={scoreBody}
                  style={{ minWidth: '15%' }}
                />
                <Column header="Gap" body={gapBody} style={{ minWidth: '10%' }} />
              </Table>
            </div>
          </div>
        </div>
      </div>
    </AppMenu>
  );
};

export default AssessmentDashboard;
