import React from 'react';
import { Redirect } from 'react-router-dom';
import Area from './pages/Area/Area';
import Assessment from './pages/Assessment/Assessment';
import AssessmentDashboard from './pages/Assessment/AssessmentDashboard';
import AssessmentFormTab from './pages/Assessment/AssessmentFormTab';
import Dashboard from './pages/Dashboard/Dashboard';
import Indicator from './pages/Indicator/Indicator';
import IndicatorForm from './pages/Indicator/IndicatorForm';
import Login from './pages/Login/Login';
import Project from './pages/Project/Project';
import Target from './pages/Target/Target';
import TargetFormTab from './pages/Target/TargetFormTab';
import { FeatureFlag } from './utils/env';

const menuGeneratorAdmin = () => {
  let res = [
    {
      label: 'Menu',
      items: [
        { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard' },
        { label: 'Engagement', icon: 'pi pi-fw pi-list', to: '/project' }
      ]
    }
  ];

  if (FeatureFlag.MASTER_PAGE_SECTION) {
    let masterPages = [];

    if (FeatureFlag.AREA_MASTER_PAGE) {
      masterPages.push({ label: 'Area', icon: 'pi pi-fw pi-map-marker', to: '/area' });
    }

    if (FeatureFlag.INDICATOR_MASTER_PAGE) {
      masterPages.push({ label: 'Indikator', icon: 'pi pi-fw pi-key', to: '/indicator' });
    }

    res.push({
      label: 'Master Data',
      items: masterPages
    });
  }

  return res;
};

const menuGeneratorClient = () => {
  let res = [
    {
      label: 'Menu',
      items: [
        { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard' },
        { label: 'Engagement', icon: 'pi pi-fw pi-list', to: '/project' }
      ]
    }
  ];

  return res;
};

const routesGenerator = () => {
  let res = [
    { path: '/', element: <Redirect to="/dashboard" /> },
    { path: '/login', element: <Login /> },
    { path: '/dashboard', element: <Dashboard /> },
    { path: '/project', element: <Project /> },
    { path: '/project/:id/assessment', element: <Assessment /> },
    { path: '/project/:id/assessment/edit', element: <AssessmentFormTab /> },
    { path: '/project/:id/assessment/dashboard', element: <AssessmentDashboard /> },
    { path: '/project/:id/target', element: <Target /> },
    { path: '/project/:id/target/edit', element: <TargetFormTab /> }
  ];

  if (FeatureFlag.AREA_MASTER_PAGE) {
    res.push({ path: '/area', element: <Area /> });
  }

  if (FeatureFlag.INDICATOR_MASTER_PAGE) {
    const indicatorRoutes = [
      { path: '/indicator', element: <Indicator /> },
      { path: '/indicator/create', element: <IndicatorForm /> },
      { path: '/indicator/edit/:id', element: <IndicatorForm /> }
    ];
    res.push(...indicatorRoutes);
  }

  return res;
};

export const menuAdmin = menuGeneratorAdmin();
export const menuClient = menuGeneratorClient();

export const routes = routesGenerator();
