import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useData } from '../../hooks/data';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { getProjectFromDataUsingPathname, statusName, tagSeverity } from '../../utils/utils';
import AppMenu from '../../components/Menu/AppMenu';
import Table from '../../components/Table/Table';
import TableActionButton from '../../components/Table/TableActionButton';
import pptxgen from 'pptxgenjs';
import { Button } from 'primereact/button';
import { Timestamp } from 'firebase/firestore';
import { FirebaseService } from '../../service/FirebaseService';
import { keys } from '../../utils/keys';
import { Dialog } from 'primereact/dialog';

const Assessment = () => {
  const history = useHistory();
  const data = useData();
  const toast = useRef(null);
  const [project, setProject] = useState(null);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isShowingSubmitAlert, setIsShowingSubmitAlert] = useState(false);
  const [isShowingSubmitDialog, setIsShowingSubmitDialog] = useState(false);

  useEffect(() => {
    let ref = getProjectFromDataUsingPathname(history.location.pathname, data);

    if (ref !== null) {
      ref = configureProject(ref);
      setProject(ref);
    }
  }, [data]);

  const configureProject = (ref) => {
    ref.areas.map((a) => {
      if (a.status_code === undefined) {
        a.status_code = 'open';
      }
    });
    return ref;
  };

  const parseNumberValue = (value) => {
    if (value > 0) {
      return value.toFixed(2);
    }
    return value;
  };

  const handleToast = (type, text) => {
    toast.current.show({ severity: type, summary: text, life: 3000 });
  };

  const gapBody = (rowData) =>
    parseNumberValue(Math.abs(rowData.target_value - rowData.score_value));

  const statusBody = (rowData) => (
    <Tag
      severity={tagSeverity(rowData.status_code)}
      className="uppercase px-2"
      value={statusName(rowData.status_code)}
      style={{ letterSpacing: 0.3, fontSize: 12 }}
    />
  );

  const arrData = [
    {
      name: 'Target Maturitas',
      labels: project ? project.areas.map((val) => val.index) : [],
      values: project ? project.areas.map((val) => val.target_value) : []
    },
    {
      name: 'Nilai Maturitas',
      labels: project ? project.areas.map((val) => val.index) : [],
      values: project ? project.areas.map((val) => val.score_value) : []
    }
  ];

  const lockProject = () => {
    setIsLoadingSubmit(true);

    // FirebaseService.setDocument(
    //   keys.PROJECTS_COLLECTION + '/' + project.id,
    //   {
    //     is_submitted: true,
    //     updated_at: Timestamp.now()
    //   },
    //   true
    // ).then(
    //   () => {
    //     project.is_submitted = true;
    //     handleToast('success', 'Data Engagement berhasil disubmit!');
    //     setIsLoadingSubmit(false);
    //   },
    //   (err) => {
    //     handleToast('error', 'Data Engagement tidak berhasil disubmit!');
    //     setIsLoadingSubmit(false);
    //     console.log('Error when submitting engagement / project. ' + err);
    //   }
    // );
  };

  const checkProjectCompleteness = () => {
    let result = true;
    project.areas.forEach((object) => {
      if (object.score_value < 1 || object.evidence === null || object.evidence === '') {
        result = false;
      }
    });

    return result;
  };

  const onSubmit = () => {
    if (checkProjectCompleteness() === true) {
      setIsShowingSubmitDialog(true);
      return;
    }
    return setIsShowingSubmitAlert(true);
  };

  const exportData = () => {
    const d = new Date();

    //00. Intial
    const ref = getProjectFromDataUsingPathname(history.location.pathname, data);

    let pres = new pptxgen();

    pres.author = 'Altha - Risk Assessment';
    pres.layout = 'LAYOUT_WIDE';
    pres.subject = 'Risk Assessment Report';

    // 01. Slide Master
    pres.defineSlideMaster({
      title: 'MASTER_SLIDE',
      background: { color: 'FFFFFF' },
      objects: [
        {
          text: {
            text: 'Strictly Confidential',
            options: {
              x: 2,
              y: 7.25,
              fontSize: 8,
              fontFace: 'Open Sans',
              align: 'center',
              italic: true,
              color: 'FF0000'
            }
          }
        },
        {
          text: {
            text: 'Copyright © ' + d.getFullYear() + ' by Altha Consulting. All rights reserved',
            options: { x: 2, y: 7.4, fontSize: 8, fontFace: 'Open Sans', align: 'center' }
          }
        },
        {
          image: {
            path: 'https://www.altha.co.id/img/logo/navlogo.dark.png',
            x: 0.1,
            y: 7.2,
            w: 0.66,
            h: 0.27
          }
        }
      ],
      slideNumber: { x: '97%', y: '97%', fontSize: 8, fontFace: 'Open Sans', color: '888888' }
    });

    // 02-1. Content Exsum
    let slide = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    let textHeader = 'Executive Summary Risk Maturity';
    let textboxHeader = {
      x: 0.25,
      y: 0.2,
      color: '004A92',
      fontFace: 'Open Sans',
      fontSize: 12,
      bold: true,
      italic: true
    };
    slide.addText(textHeader, textboxHeader);

    let textTitle = 'Hasil Penilaian Tingkat Maturitas Risiko';
    let textboxTitle = {
      x: 0.25,
      y: 0.5,
      color: '363636',
      fontFace: 'Open Sans',
      fontSize: 18,
      bold: false
    };
    slide.addText(textTitle, textboxTitle);

    let textTarget = 'Target Maturitas';
    let textboxTarget = {
      x: 6.3,
      y: 0.7,
      fontFace: 'Open Sans',
      fontSize: 11,
      bold: true,
      align: 'center',
      valign: 'middle',
      h: 0.26,
      w: 2.17
    };
    slide.addText(textTarget, textboxTarget);

    let textNilai = 'Nilai Maturitas';
    let textboxNilai = {
      x: 8.6,
      y: 0.7,
      fontFace: 'Open Sans',
      fontSize: 11,
      bold: true,
      align: 'center',
      valign: 'middle',
      h: 0.26,
      w: 2.17
    };
    slide.addText(textNilai, textboxNilai);

    let textGap = 'Gap';
    let textboxGap = {
      x: 10.9,
      y: 0.7,
      fontFace: 'Open Sans',
      fontSize: 11,
      bold: true,
      align: 'center',
      valign: 'middle',
      h: 0.26,
      w: 2.17
    };
    slide.addText(textGap, textboxGap);

    slide.addText(ref.target_value.toFixed(2), {
      shape: pres.shapes.ROUNDED_RECTANGLE,
      x: 6.3,
      y: 1,
      w: 2.17,
      h: 0.6,
      fill: { color: 'F2F2F2' },
      align: 'center',
      valign: 'middle',
      fontSize: 18,
      fontFace: 'Open Sans',
      line: { color: 'A6A6A6', size: 1 },
      rectRadius: 0.1,
      bold: true
    });

    slide.addText(ref.score_value.toFixed(2), {
      shape: pres.shapes.ROUNDED_RECTANGLE,
      x: 8.6,
      y: 1,
      w: 2.17,
      h: 0.6,
      fill: { color: 'F2F2F2' },
      align: 'center',
      valign: 'middle',
      fontSize: 18,
      fontFace: 'Open Sans',
      line: { color: 'A6A6A6', size: 1 },
      rectRadius: 0.1,
      bold: true
    });

    if (ref.target_value <= ref.score_value) {
      slide.addText(
        Math.abs((ref.score_value.toFixed(2) - ref.target_value).toFixed(2)).toFixed(2),
        {
          shape: pres.shapes.ROUNDED_RECTANGLE,
          x: 10.9,
          y: 1,
          w: 2.17,
          h: 0.6,
          fill: { color: 'F2F2F2' },
          align: 'center',
          valign: 'middle',
          fontSize: 18,
          fontFace: 'Open Sans',
          line: { color: 'A6A6A6', size: 1 },
          rectRadius: 0.1,
          color: '00B050',
          bold: true
        }
      );
    } else {
      slide.addText(
        Math.abs((ref.score_value.toFixed(2) - ref.target_value).toFixed(2)).toFixed(2),
        {
          shape: pres.shapes.ROUNDED_RECTANGLE,
          x: 10.9,
          y: 1,
          w: 2.17,
          h: 0.6,
          fill: { color: 'F2F2F2' },
          align: 'center',
          valign: 'middle',
          fontSize: 18,
          fontFace: 'Open Sans',
          line: { color: 'A6A6A6', size: 1 },
          rectRadius: 0.1,
          color: 'FF0000',
          bold: true
        }
      );
    }

    let result = [];
    let rows = [
      {
        text: '#',
        options: {
          align: 'center',
          fontFace: 'Open Sans',
          bold: true,
          fontSize: 11,
          fill: { color: 'dae3f2' }
        }
      },
      {
        text: 'Area',
        options: {
          align: 'center',
          fontFace: 'Open Sans',
          bold: true,
          italic: false,
          fontSize: 11,
          fill: { color: 'dae3f2' }
        }
      },
      {
        text: 'Target Maturitas',
        options: {
          align: 'center',
          fontFace: 'Open Sans',
          bold: true,
          italic: false,
          fontSize: 11,
          fill: { color: 'dae3f2' }
        }
      },
      {
        text: 'Nilai Maturitas',
        options: {
          align: 'center',
          fontFace: 'Open Sans',
          bold: true,
          italic: false,
          fontSize: 11,
          fill: { color: 'dae3f2' }
        }
      },
      {
        text: 'Gap',
        options: {
          align: 'center',
          fontFace: 'Open Sans',
          bold: true,
          italic: true,
          fontSize: 11,
          fill: { color: 'dae3f2' }
        }
      }
    ];
    result.push(rows);

    ref.areas.forEach((areaObj, areaIndex) => {
      if (areaObj.target_value <= areaObj.score_value) {
        let content = [
          {
            text: areaIndex + 1,
            options: { align: 'center', fontFace: 'Open Sans', fontSize: 11 }
          },
          {
            text: areaObj.index + ' - ' + areaObj.name,
            options: { align: 'left', fontFace: 'Open Sans', fontSize: 11 }
          },
          {
            text: areaObj.target_value.toFixed(2),
            options: { align: 'center', fontFace: 'Open Sans', fontSize: 11 }
          },
          {
            text: areaObj.score_value.toFixed(2),
            options: { align: 'center', fontFace: 'Open Sans', fontSize: 11 }
          },
          {
            text: (areaObj.score_value.toFixed(2) - areaObj.target_value).toFixed(2),
            options: { align: 'center', fontFace: 'Open Sans', fontSize: 11, color: '00B050' }
          }
        ];

        result.push(content);
      } else {
        let content = [
          {
            text: areaIndex + 1,
            options: { align: 'center', fontFace: 'Open Sans', fontSize: 11, fill: 'FFFFFF' }
          },
          {
            text: areaObj.index + ' - ' + areaObj.name,
            options: { align: 'left', fontFace: 'Open Sans', fontSize: 11, fill: 'FFFFFF' }
          },
          {
            text: areaObj.target_value.toFixed(2),
            options: { align: 'center', fontFace: 'Open Sans', fontSize: 11, fill: 'FFFFFF' }
          },
          {
            text: areaObj.score_value.toFixed(2),
            options: { align: 'center', fontFace: 'Open Sans', fontSize: 11, fill: 'FFFFFF' }
          },
          {
            text: Math.abs(
              (areaObj.score_value.toFixed(2) - areaObj.target_value).toFixed(2)
            ).toFixed(2),
            options: { align: 'center', fontFace: 'Open Sans', fontSize: 11, color: 'FF0000' }
          }
        ];

        result.push(content);
      }
    });

    slide.addTable(result, {
      x: 6.3,
      y: 1.9,
      w: 12.68,
      colW: [0.57, 2.56, 1.25, 1.25, 1.25],
      rowH: [0.4],
      valign: 'middle',
      border: { pt: '0.7', color: 'F0F0F0' }
    });

    slide.addShape(pres.shapes.RECTANGLE, {
      x: 0.38,
      y: 0.85,
      w: 5.7,
      h: 6.1,
      line: { color: 'A6A6A6', width: 1 }
    });

    let optsChartRadar1 = {
      x: -0.58,
      y: 1,
      w: 7.59,
      h: 5.9,

      radarStyle: 'marker',
      lineDataSymbol: 'circle',

      catAxisLabelFontFace: 'Open Sans',
      catAxisLabelBold: true,
      lineDataSymbolLineSize: 4,
      lineDataSymbolSize: 4,
      valAxisLineSize: 0,
      valAxisLineColor: 'ffffff',
      valGridLine: { color: 'D9D9D9', size: 0.75 },
      valAxisHidden: false,
      chartColors: ['4472C4', 'ED7D31'],

      showLegend: true,
      legendFontFace: 'Open Sans',
      legendFontSize: 8,
      legendPos: 't',

      titleColor: '595959',
      titleFontFace: 'Open Sans',
      titleFontSize: 12,
      showTitle: true,
      title: 'Persebaran Nilai Maturitas Risiko',
      titleBold: true
    };

    slide.addChart(pres.charts.RADAR, arrData, optsChartRadar1);

    // 02-2. Content Detail
    ref.areas.forEach((areaObj, areaIndex) => {
      let detail = pres.addSlide({ masterName: 'MASTER_SLIDE' });

      let textHeaderDetail = 'Detail Penilaian Tingkat Maturitas Risiko';
      let textboxHeaderDetail = {
        x: 0.25,
        y: 0.2,
        color: '004A92',
        fontFace: 'Open Sans',
        fontSize: 12,
        bold: true,
        italic: false
      };
      detail.addText(textHeaderDetail, textboxHeaderDetail);
      detail.addShape(pres.shapes.RECTANGLE, {
        x: 0,
        y: 0.3,
        w: 0.28,
        h: 0.63,
        fill: { color: '314673' },
        line: { type: 'none' }
      });

      let textTitleDetail = 'Detail Penilaian Tingkat Maturitas Risiko';
      let textboxTitleDetail = {
        x: 0.25,
        y: 0.5,
        color: '363636',
        fontFace: 'Open Sans',
        fontSize: 18,
        bold: false
      };
      detail.addText(textTitleDetail, textboxTitleDetail);

      let textTitleDetailArea =
        'Area : ' + areaObj.name + ' (Nilai : ' + areaObj.score_value.toFixed(2) + ' )';
      let textboxTitleDetailArea = {
        x: 0.25,
        y: 0.78,
        color: '363636',
        fontFace: 'Open Sans',
        fontSize: 18,
        bold: true
      };
      detail.addText(textTitleDetailArea, textboxTitleDetailArea);

      let resultDetail = [];
      let rowsDetail = [
        {
          text: 'Indikator',
          options: {
            align: 'center',
            fontFace: 'Open Sans',
            bold: true,
            italic: false,
            fontSize: 11,
            fill: { color: 'dae3f2' }
          }
        },
        {
          text: 'Description',
          options: {
            align: 'center',
            fontFace: 'Open Sans',
            bold: true,
            italic: false,
            fontSize: 11,
            fill: { color: 'dae3f2' }
          }
        },
        {
          text: 'Target Maturitas',
          options: {
            align: 'center',
            fontFace: 'Open Sans',
            bold: true,
            italic: false,
            fontSize: 11,
            fill: { color: 'dae3f2' }
          }
        },
        {
          text: 'Nilai Maturitas',
          options: {
            align: 'center',
            fontFace: 'Open Sans',
            bold: true,
            italic: false,
            fontSize: 11,
            fill: { color: 'dae3f2' }
          }
        },
        {
          text: 'Evidence',
          options: {
            align: 'center',
            fontFace: 'Open Sans',
            bold: true,
            italic: true,
            fontSize: 11,
            fill: { color: 'dae3f2' }
          }
        }
      ];
      resultDetail.push(rowsDetail);

      areaObj.indicators.map((indicatorObj) => {
        let rowsDetailContent = [
          {
            text: [
              { text: indicatorObj.index, options: { bold: true, break: false } },
              { text: ' - ' + indicatorObj.description, options: { bold: false, break: false } }
            ],
            options: {
              align: 'left',
              fontFace: 'Open Sans',
              fontSize: 11,
              fill: { color: 'ffffff' }
            }
          },
          {
            text: indicatorObj.notes,
            options: {
              align: 'left',
              fontFace: 'Open Sans',
              fontSize: 11,
              fill: { color: 'ffffff' }
            }
          },
          {
            text: indicatorObj.target_value,
            options: {
              align: 'center',
              fill: { color: 'ffffff' },
              fontFace: 'Open Sans',
              fontSize: 11
            }
          },
          {
            text: indicatorObj.score_value,
            options: {
              align: 'center',
              fill: { color: 'ffffff' },
              fontFace: 'Open Sans',
              fontSize: 11
            }
          },
          {
            text: indicatorObj.evidence,
            options: {
              align: 'left',
              fill: { color: 'ffffff' },
              fontFace: 'Open Sans',
              fontSize: 11
            }
          }
        ];
        resultDetail.push(rowsDetailContent);
      });

      detail.addTable(resultDetail, {
        x: 0.23,
        y: 1.2,
        w: 12.68,
        colW: [3.13, 4.52, 1.06, 1.06, 2.91],
        align: 'left',
        border: { pt: '0.7', color: 'F0F0F0' },
        valign: 'middle'
      });
    });

    //03. Create File
    let filename = ref.entity + ' - Hasil Penilaian Tingkat Maturitas Risiko.pptx';
    pres.title = filename;
    pres.writeFile({ fileName: filename });
  };

  const actionBody = (rowData) => {
    const index = project.areas.findIndex((obj) => obj.id === rowData.id);

    return (
      <TableActionButton
        color="warning"
        icon="pi pi-pencil"
        tooltipText="Edit"
        onClick={() => history.push(`${history.location.pathname}/edit`, index)}
      />
    );
  };

  const assessmentProgress = () => {
    const completed = project?.areas.filter((val) => val.status_code === 'completed');
    return `${completed?.length || 0} / ${project?.areas.length || 0}`;
  };

  const submitDialogFooter = () => {
    return (
      <div>
        <Button
          className="pi p-button p-button-outlined"
          label="Ya"
          onClick={() => lockProject()}
        />
        <Button label="Tidak" onClick={() => setIsShowingSubmitAlert(false)} />
      </div>
    );
  };

  const submitAlertFooter = () => {
    return (
      <div>
        <Button label="Mengerti" onClick={() => setIsShowingSubmitAlert(false)} />
      </div>
    );
  };

  return (
    <AppMenu>
      <div className="card">
        <div className="flex align-items-center justify-content-between">
          <h3 className="font-bold mb-0">Penilaian</h3>
          <div>
            <Button label="Export Report" icon="pi pi-download" onClick={exportData} />
            {project !== null &&
            project.is_submitted !== undefined &&
            project.is_submitted === true ? null : (
              <Button
                className="p-button p-button-outlined ml-2"
                label="Submit"
                icon="pi pi-save"
                onClick={onSubmit}
                loading={isLoadingSubmit}
              />
            )}
          </div>
        </div>
        <div className="grid mt-2 mb-4">
          <div className="col-12 lg:col-2 md:col-3">
            <div className="form-card-border assessment-box">
              <span className="text-sm">Target Tingkat Maturitas</span>
              <h2 className="mt-1 mb-0 text-yellow-400">{project?.target_value.toFixed(2) || 0}</h2>
            </div>
          </div>
          <div className="col-12 lg:col-2 md:col-3">
            <div className="form-card-border assessment-box">
              <span className="text-sm">Hasil Penilaian Tingkat Maturitas</span>
              <h2 className="mt-1 mb-0 text-yellow-400">{project?.score_value.toFixed(2) || 0}</h2>
            </div>
          </div>
          <div className="col-12 lg:col-2 md:col-3">
            <div className="form-card-border assessment-box">
              <span className="text-sm">Progress Pengisian Assessment</span>
              <h2 className="mt-1 mb-0 text-yellow-400">{assessmentProgress()}</h2>
            </div>
          </div>
        </div>
        <Table data={project?.areas || []} loading={data.areaIsLoading} totalRows={20}>
          <Column field="index" header="Kode Area" style={{ minWidth: '10%' }} />
          <Column field="name" header="Area" style={{ minWidth: '30%' }} />
          <Column
            field="target_value"
            header="Target Tingkat Maturitas"
            body={(rowData) => parseNumberValue(rowData.target_value)}
            style={{ minWidth: '14%' }}
          />
          <Column
            field="score_value"
            header="Hasil Penilaian Tingkat Maturitas"
            body={(rowData) => parseNumberValue(rowData.score_value)}
            style={{ minWidth: '14%' }}
          />
          <Column field="status" header="Gap" body={gapBody} style={{ minWidth: '10%' }} />
          <Column field="status" header="Status" body={statusBody} style={{ minWidth: '15%' }} />
          <Column header="Action" body={actionBody} style={{ minWidth: '7%' }} />
        </Table>
      </div>
      <Dialog
        header="Data Belum Lengkap"
        footer={submitAlertFooter()}
        draggable={false}
        resizeable={false}
        focusOnShow={false}
        visible={isShowingSubmitAlert}
        style={{ maxWidth: 450 }}
        onClick={() => setIsShowingSubmitAlert(false)}>
        <p>
          Untuk bisa melakukan submit data. Semua area harus sudah terisi baik tingkat maturitas
          maupun evidencenya.
        </p>
      </Dialog>
      <Dialog
        header="Apakah Anda Yakin?"
        footer={submitDialogFooter()}
        draggable={false}
        resizeable={false}
        focusOnShow={false}
        visible={isShowingSubmitDialog}
        style={{ maxWidth: 450 }}
        onClick={() => setIsShowingSubmitDialog(false)}>
        <p>
          Setelah disubmit, assessment ini akan terkunci dan data tidak akan bisa diubah kembali.
        </p>
      </Dialog>
      <Toast ref={toast} />
    </AppMenu>
  );
};

export default Assessment;
