export const getBaseCollection = () => {
  /// Only assign 'dev' or 'prod'.
  /// Change the value of this variable to make it target DEV or PROD environment.
  const environment = 'prod';

  return environment === 'dev' ? '/risk-assessment-dev' : '/risk-assessment';
};

export const FeatureFlag = {
  ADD_ENGAGEMENT_BUTTON: true,
  MASTER_PAGE_SECTION: true,
  AREA_MASTER_PAGE: true,
  INDICATOR_MASTER_PAGE: true
};
