import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import AlthaLogo from '../../assets/images/altha.png';
import { Button } from 'primereact/button';

export const Navbar = (props) => {
    return (
        <div className='layout-topbar'>
            <Link to='/' className='layout-topbar-logo'>
                <img src={AlthaLogo} alt='logo' />
            </Link>

            <button
                type='button'
                className='p-link  layout-menu-button layout-topbar-button'
                onClick={props.onToggleMenuClick}
            >
                <i className='pi pi-bars' />
            </button>

            <button
                type='button'
                className='p-link layout-topbar-menu-button layout-topbar-button'
                onClick={props.onMobileTopbarMenuClick}
            >
                <i className='pi pi-ellipsis-v' />
            </button>

            <ul
                className={classNames('layout-topbar-menu lg:flex origin-top', {
                    'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive
                })}
            >
                <li>
                    <Button
                        className='p-link layout-topbar-button'
                        onClick={props.onMobileSubTopbarMenuClick}
                    >
                        <i className='pi pi-sign-out w-full' />
                        <span>Log Out</span>
                    </Button>
                </li>
            </ul>
        </div>
    );
};
