export const tagSeverity = (type) => {
  switch (type) {
    case 'completed':
      return 'success';
    case 'in_progress':
      return 'warning';
    default:
      return 'info';
  }
};

export const statusName = (type) => {
  switch (type) {
    case 'completed':
      return 'selesai';
    case 'in_progress':
      return 'pengisian penilaian';
    case 'open':
      return 'Baru';
    default:
      return type;
  }
};

export const getProjectFromDataUsingPathname = (pathname, data) => {
  if (data.projects.length === 0) {
    return null;
  }

  const projectId = pathname.split('/')[2];
  let ref = data.projects.filter((obj) => obj.id === projectId);

  if (ref.length === 0) {
    return null;
  }

  return ref[0];
};

export const deepCopy = (object) => {
  return JSON.parse(JSON.stringify(object));
};

export const getUserFromData = (email, data) => {
  if (data.user.length === 0) {
    return null;
  }

  let ref =  data.user.filter(function(emailUser) {
    return emailUser.email === email;
  });

  if (ref.length === 0) {
    return null;
  }

  return ref[0];
};