import React from 'react';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';

const TableActionButton = (props) => {
  const { buttonText, color, className, icon, tooltipText, onClick } = props;

  return (
    <Button
      type="button"
      label={buttonText}
      icon={icon}
      tooltip={tooltipText}
      tooltipOptions={{ position: 'bottom', className: 'table-tooltip' }}
      className={classNames('p-button-sm w-auto mr-2', { [`p-button-${color}`]: color }, className)}
      style={{ padding: '0.5rem 0.6rem' }}
      onClick={onClick}
    />
  );
};

export default TableActionButton;
