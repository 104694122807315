import { createContext } from 'react';
import { keys } from './keys';

const authState = { keys, ACCESS_TOKEN: null };

export const getAccessToken = () => {
  return localStorage.getItem(keys.ACCESS_TOKEN);
};

export const setAccessToken = (token) => {
  localStorage.setItem(keys.ACCESS_TOKEN, token);
  createContext(authState);
};

export const removeAccessToken = () => {
  localStorage.removeItem(keys.ACCESS_TOKEN);
};
