import React, { useEffect, useRef, useState } from 'react';
import { useData } from '../../hooks/data';
import { FirebaseService } from '../../service/FirebaseService';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import AppMenu from '../../components/Menu/AppMenu';
import Table from '../../components/Table/Table';
import TableActionButton from '../../components/Table/TableActionButton';
import AreaForm from './AreaForm';
import { keys } from '../../utils/keys';

const Area = () => {
  const data = useData();
  const toast = useRef(null);
  const [showForm, setShowForm] = useState(false);
  const [detailData, setDetailData] = useState(null);

  useEffect(() => {
    if (data.areaListenerError !== '') {
      onFailedAreaUpdate(data.areaListenerError);
    }
  }, [data]);

  const onFailedAreaUpdate = (e) => {
    handleToast('error', 'Gagal mengambil data area. ', e.message);
  };

  const onAreaFormSuccess = (m) => {
    handleToast('success', m);
    window.scrollTo(0, document.body.scrollHeight);
  };

  const onAreaFormFailed = (m) => {
    handleToast('error', m);
  };

  const handleAddForm = () => {
    setDetailData({ order_id: data.areas.length + 1 });
    setShowForm(true);
  };

  const handleEditForm = (rowData) => {
    setDetailData(rowData);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setDetailData(null);
    setShowForm(false);
  };

  const handleToast = (type, text) => {
    toast.current.show({ severity: type, summary: text, life: 3000 });
  };

  const handleDeletePopup = (areaId) => {
    confirmDialog({
      message: 'Apakah Anda yakin akan menghapus area ini?',
      header: 'Hapus Area',
      acceptLabel: 'Ya',
      acceptIcon: 'pi pi-trash',
      acceptClassName: 'p-button-danger',
      rejectLabel: 'Tidak',
      rejectIcon: 'pi pi-times',
      accept: () => deleteAreaHandler(areaId)
    });
  };

  const deleteAreaHandler = (areaId) => {
    const index = data.areas.findIndex((doc) => doc.id === areaId);
    FirebaseService.deleteDocument(keys.AREAS_COLLECTION + '/' + data.areas[index].id).then(
      () => {
        handleToast('success', 'Berhasil menghapus area');
      },
      (e) => {
        handleToast('error', 'Gagal menghapus area. ', e.message);
      }
    );
  };

  const actionBody = (rowData) => (
    <div className="flex align-items-center">
      <TableActionButton
        color="warning"
        icon="pi pi-pencil"
        tooltipText="Edit Area"
        onClick={() => handleEditForm(rowData)}
      />
      <TableActionButton
        color="danger"
        icon="pi pi-trash"
        tooltipText="Hapus Area"
        onClick={() => handleDeletePopup(rowData.id)}
      />
    </div>
  );

  return (
    <AppMenu>
      <div className="card">
        <div className="flex align-items-center justify-content-between mb-4">
          <h3 className="font-bold mb-0">Area</h3>
          <Button label="Tambah Area" icon="pi pi-plus" onClick={handleAddForm} />
        </div>
        <Table data={data.areas} loading={data.areaIsLoading} totalRows={50}>
          <Column field="index" header="Kode Area" style={{ minWidth: '10%' }} />
          <Column field="name" header="Area" style={{ minWidth: '30%' }} />
          <Column
            field="description"
            header="Deskripsi Area Evaluasi"
            style={{ minWidth: '50%' }}
          />
          <Column header="Action" body={actionBody} style={{ minWidth: '10%' }} />
        </Table>
      </div>

      <AreaForm
        data={detailData}
        show={showForm}
        setShow={handleCloseForm}
        setSuccess={onAreaFormSuccess}
        setFailed={onAreaFormFailed}
      />
      <Toast ref={toast} />
    </AppMenu>
  );
};

export default Area;
