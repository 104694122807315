import { getBaseCollection } from './env';

export const keys = {
  ACCESS_TOKEN: 'access_token',
  DATA_DOCUMENT: getBaseCollection() + '/data',
  AREAS_COLLECTION: getBaseCollection() + '/data/areas',
  INDICATORS_COLLECTION: getBaseCollection() + '/data/indicators',
  PROJECTS_COLLECTION: getBaseCollection() + '/data/projects',
  VERSIONS_COLLECTION: getBaseCollection() + '/data/versions',
  USERS_COLLECTION: getBaseCollection() + '/data/users',
  // USERS_COLLECTION: '/risk-assessment-dev/data/users',
  
  createdAt: 'created_at',
  description: 'description',
  index: 'index',
  name: 'name',
  orderId: 'order_id',
  updatedAt: 'updated_at'
};
