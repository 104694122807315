import React, { createContext, useContext, useEffect, useState } from 'react';
import { collection } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { FirebaseService } from '../service/FirebaseService';
import { keys } from '../utils/keys';

const dataType = {
  area: 'area',
  indicator: 'indicator',
  project: 'project',
  user : 'user'
};

const defaultValue = {
  areas: [],
  areaListenerError: '',
  areaIsLoading: false,

  indicators: [],
  indicatorListenerError: '',
  indicatorIsLoading: false,

  projects: [],
  projectListenerError: '',
  projectIsLoading: false,

  users: [],
  userListenerError: '',
  userIsLoading: false
};

const Context = createContext(defaultValue);

export const DataContext = (props) => {
  const [areas, setAreas] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [projects, setProjects] = useState([]);
  const [user, setUser] = useState([]);

  const [areaSnapshots, areaIsLoading, areaError] = useCollection(
    collection(FirebaseService.init(), keys.AREAS_COLLECTION)
  );
  const [indicatorSnapshots, indicatorIsLoading, indicatorError] = useCollection(
    collection(FirebaseService.init(), keys.INDICATORS_COLLECTION)
  );
  const [projectSnapshots, projectIsLoading, projectError] = useCollection(
    collection(FirebaseService.init(), keys.PROJECTS_COLLECTION)
  );
  const [userSnapshots, userIsLoading, userError] = useCollection(
    collection(FirebaseService.init(), keys.USERS_COLLECTION)
  );

  useEffect(() => {
    if (areaError !== undefined) {
      console.log('Error fetching data AREA: ', areaError.message);
    } else if (areaSnapshots !== undefined) {
      onReceiveSnapshotsUpdate(areaSnapshots, dataType.area);
    }
  }, [areaSnapshots, areaIsLoading, areaError]);

  useEffect(() => {
    if (indicatorError !== undefined) {
      console.log('Error fetching data INDICATOR: ', indicatorError.message);
    } else if (indicatorSnapshots !== undefined) {
      onReceiveSnapshotsUpdate(indicatorSnapshots, dataType.indicator);
    }
  }, [indicatorSnapshots, indicatorIsLoading, indicatorError]);

  useEffect(() => {
    if (projectError !== undefined) {
      console.log('Error fetching data PROJECT: ', projectError.message);
    } else if (projectSnapshots !== undefined) {
      onReceiveSnapshotsUpdate(projectSnapshots, dataType.project);
    }
  }, [projectSnapshots, projectIsLoading, projectError]);

  useEffect(() => {
    if (userError !== undefined) {
      console.log('Error fetching data USER: ', userError.message);
    } else if (userSnapshots !== undefined) {
      onReceiveSnapshotsUpdate(userSnapshots, dataType.user);
    }
  }, [userSnapshots, userIsLoading, userError]);

  const onReceiveSnapshotsUpdate = (snapshots, type) => {
    const changes = snapshots.docChanges();
    let data = [];
    let dataCount = 0;
    changes.forEach((c) => {
      if (c.type === 'added') {
        let ref = c.doc.data();
        ref.id = c.doc.id;
        data.push(ref);

        dataCount++;
        if (dataCount >= changes.length) {
          addData(data, type);
        }
      } else if (c.type === 'modified') {
        modifyData(c.doc, type);
      } else {
        removeData(c.doc.id, type);
      }
    });
  };

  const getData = (type) => {
    if (type === dataType.area) {
      return areas;
    } else if (type === dataType.indicator) {
      return indicators;
    }else if(type === dataType.user){
      return user;
    } 
    else {
      return projects;
    }
  };

  const setData = (data, type) => {
    if (type === dataType.area) {
      setAreas(data);
    } else if (type === dataType.indicator) {
      setIndicators(data);
    } else if (type === dataType.user) {
      setUser(data);
    }
    else {
      setProjects(data);
    }
  };

  const addData = (refs, type) => {
    let keys = [];
    let result = getData(type);

    refs.forEach((refObj) => keys.push(refObj.id));
    result = result.filter((areaObj) => {
      return !keys.includes(areaObj.id);
    });
    result = result.concat(refs);
    result.sort((a, b) => a.created_at - b.created_at);

    setData(result, type);
  };

  const modifyData = (ref, type) => {
    let result = getData(type);

    if (result.length <= 0) {
      return;
    }

    const index = result.findIndex((doc) => doc.id === ref.id);
    let data = ref.data();
    data.id = ref.id;
    result[index] = data;

    setData(result, type);
  };

  const removeData = (refId, type) => {
    let result = getData(type);

    if (result.length <= 0) {
      return;
    }
    result = result.filter((d) => d.id !== refId);

    setData(result, type);
  };

  const consolidateData = () => {
    return {
      areas: areas,
      indicators: indicators,
      projects: projects,
      user : user,

      areaListenerError: areaError === undefined ? '' : areaError.message,
      indicatorListenerError: indicatorError === undefined ? '' : indicatorError.message,
      projectListenerError: projectError === undefined ? '' : projectError.message,
      userListenerError: userError === undefined ? '' : userError.message,

      areaIsLoading: areaIsLoading,
      indicatorIsLoading: indicatorIsLoading,
      projectIsLoading: projectIsLoading,
      userIsLoading: userIsLoading

    };
  };

  return <Context.Provider value={consolidateData()}>{props.children}</Context.Provider>;
};

export const useData = () => useContext(Context);
