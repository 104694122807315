import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useData } from '../../hooks/data';
import { FirebaseService } from '../../service/FirebaseService';
import { Timestamp } from 'firebase/firestore';
import { deepCopy, getProjectFromDataUsingPathname } from '../../utils/utils';
import AppMenu from '../../components/Menu/AppMenu';
import ScoringView, { ScoringViewMode } from '../../components/ScoringView/ScoringView';
import { Toast } from 'primereact/toast';
import { keys } from '../../utils/keys';

const TargetFormTab = () => {
  const history = useHistory();
  const toast = useRef(null);
  const data = useData();
  const [project, setProject] = useState(null);
  const [tabHeaders, setTabHeaders] = useState([]);
  const [renderCounter, setRenderCounter] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (history.location.state !== undefined) {
      setActiveTab(history.location.state);
    }
  }, [history]);

  useEffect(() => {
    const ref = getProjectFromDataUsingPathname(history.location.pathname, data);
    if (ref !== null) {
      const configured = configureProjectAndTabs(ref);
      setProject(deepCopy(configured.project));
      setTabHeaders(configured.tabs);
    }
  }, [data.projects]);

  const handleToast = (type, text) => {
    toast.current.show({ severity: type, summary: text, life: 3000 });
  };

  const configureProjectAndTabs = (ref) => {
    let newTabs = [];
    ref.areas.forEach((areaObj, areaIndex) => {
      newTabs.push({
        index: areaIndex,
        label: areaObj.name,
        isCompleted: areaObj.status === 'completed'
      });
      areaObj.indicators = areaObj.indicators.sort((a, b) => {
        return a.index < b.index ? -1 : 1;
      });
    });
    return { project: ref, tabs: newTabs };
  };

  const checkCompletions = () => {
    let isTargetFinished = true;
    let isAssessmentFinished = true;
    let totalIndicators = 0;
    let totalIndicatorsValue = 0;

    for (let i = 0; i < project.areas.length; i++) {
      const area = project.areas[i];

      for (let j = 0; j < area.indicators.length; j++) {
        const indicator = area.indicators[j];

        if (indicator.target_value === 0) {
          isTargetFinished = false;
        }
        if (indicator.score_value === 0) {
          isAssessmentFinished = false;
        }

        totalIndicators++;
        totalIndicatorsValue += indicator.target_value;
      }
    }

    return {
      isTargetFinished: isTargetFinished,
      isAssessmentFinished: isAssessmentFinished,
      indicatorsValue: totalIndicatorsValue / totalIndicators
    };
  };

  const saveProject = () => {
    const checks = checkCompletions();
    FirebaseService.setDocument(
      keys.PROJECTS_COLLECTION + '/' + project.id,
      {
        areas: project.areas,
        target_value: checks.indicatorsValue,
        status: checks.isTargetFinished ? 'Pengisian Penilaian' : 'Pengisian Target',
        status_code:
          checks.isTargetFinished && checks.isAssessmentFinished ? 'completed' : 'in_progress',
        updated_at: Timestamp.now()
      },
      true
    ).then(
      () => {
        setIsLoading(false);
        handleToast('success', 'Target berhasil disimpan!');
        if (activeTab !== tabHeaders.length - 1) {
          setActiveTab(activeTab + 1);
        }
      },
      (err) => {
        setIsLoading(false);
        handleToast('error', 'Gagal menyimpan data target! ' + err.message);
      }
    );
  };

  const onScoreSelection = (e, index) => {
    let totalTarget = 0;
    let newProject = project;

    newProject.areas.map((areaObj, areaIndex) => {
      if (areaIndex === activeTab) {
        let areaTarget = 0;
        areaObj.indicators.map((indicatorObj, indicatorIndex) => {
          if (indicatorIndex === index) {
            indicatorObj.target_value = indicatorObj.scores.filter(
              (o) => o.value === e.value
            )[0].value;
          }
          areaTarget += indicatorObj.target_value;
        });
        areaObj.target_value = areaTarget / areaObj.indicators.length;
        totalTarget += areaObj.target_value;
      } else {
        totalTarget += areaObj.target_value;
      }
    });

    newProject.target_value = totalTarget / newProject.areas.length;

    if (!isChanged) {
      setIsChanged(true);
    }

    setProject(newProject);
    setRenderCounter(renderCounter + 1);
  };

  const onSelectIndicator = (value) => {
    let totalTarget = 0;
    let newProject = project;

    newProject.areas.map((areaObj, areaIndex) => {
      if (areaIndex === activeTab) {
        let areaTarget = 0;
        areaObj.indicators.map((indicatorObj) => {
          indicatorObj.target_value = value;
          areaTarget += indicatorObj.target_value;
        });
        areaObj.target_value = areaTarget / areaObj.indicators.length;
        totalTarget += areaObj.target_value;
      } else {
        totalTarget += areaObj.target_value;
      }
    });

    newProject.target_value = totalTarget / newProject.areas.length;

    if (!isChanged) {
      setIsChanged(true);
    }

    setProject(newProject);
    setRenderCounter(renderCounter + 1);
  };

  const onGoBack = () => {
    history.goBack();
  };

  const onSubmit = () => {
    setIsLoading(true);
    saveProject();
  };

  const onTabChange = (e) => {
    setActiveTab(e.index);
  };

  return (
    <AppMenu>
      <ScoringView
        mode={ScoringViewMode.target}
        project={project}
        tabHeaders={tabHeaders}
        activeTab={activeTab}
        onTabChange={onTabChange}
        onScoreSelection={onScoreSelection}
        onInputChange={null}
        onGoBack={onGoBack}
        onSubmit={onSubmit}
        onSelectIndicator={onSelectIndicator}
        isLoadingSubmission={isLoading}
        isSubmitted={false}
      />
      <Toast ref={toast} />
    </AppMenu>
  );
};

export default TargetFormTab;
