import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useData } from '../../hooks/data';
import { Timestamp } from 'firebase/firestore';
import { FirebaseService } from '../../service/FirebaseService';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import AppMenu from '../../components/Menu/AppMenu';
import { keys } from '../../utils/keys';

const IndicatorForm = () => {
  const history = useHistory();
  const data = useData();
  const toast = useRef(null);
  const [refIndicator, setRefIndicator] = useState(null);
  const [area, setArea] = useState('');
  const [options, setOptions] = useState([]);
  const [index, setIndex] = useState('');
  const [indicator, setIndicator] = useState('');
  const [initial, setInitial] = useState('');
  const [repeatable, setRepeatable] = useState('');
  const [defined, setDefined] = useState('');
  const [managed, setManaged] = useState('');
  const [optimized, setOptimized] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    populateOptions();
    getIndicatorReference();
  }, [data]);

  const populateOptions = () => {
    if (data.areas !== []) {
      let newOptions = [];
      data.areas.forEach((area) => {
        newOptions.push(`${area.index} - ${area.name}`);
      });
      newOptions.sort((a, b) => a - b);
      setOptions(newOptions);
    }
  };

  const getIndicatorReference = () => {
    if (!history.location.pathname.includes('/edit') && data.indicators === []) {
      return;
    }

    const refId = history.location.pathname.split('/').pop();
    const ref = data.indicators.filter((obj) => obj.id === refId)[0];

    if (ref !== undefined && refIndicator === null) {
      setRefIndicator(ref);
      setArea(ref.area_name);
      setIndex(ref.index);
      setIndicator(ref.description);
      setInitial(ref.scores[0].description);
      setRepeatable(ref.scores[1].description);
      setDefined(ref.scores[2].description);
      setManaged(ref.scores[3].description);
      setOptimized(ref.scores[4].description);
    }
  };

  const addIndicator = () => {
    const now = Timestamp.now();
    FirebaseService.addDocument(keys.INDICATORS_COLLECTION, {
      created_at: now,
      updated_at: now,
      area_id: data.areas.filter((obj) => `${obj.index} - ${obj.name}` === area)[0].id,
      area_name: area,
      index: index,
      description: indicator,
      scores: [
        {
          key: 'Initial',
          description: initial,
          value: 1
        },
        {
          key: 'Repeatable',
          description: repeatable,
          value: 2
        },
        {
          key: 'Defined',
          description: defined,
          value: 3
        },
        {
          key: 'Managed',
          description: managed,
          value: 4
        },
        {
          key: 'Optimized',
          description: optimized,
          value: 5
        }
      ],
      is_published: false
    }).then(
      () => {
        setIsLoading(false);
        history.push('/indicator', 'created');
      },
      (err) => {
        setIsLoading(false);
        toast.current.show({
          severity: 'error',
          summary: 'Gagal menambah Indikator baru!\n' + err.message,
          life: 3000
        });
      }
    );
  };

  const modifyIndicator = () => {
    FirebaseService.setDocument(
      keys.INDICATORS_COLLECTION + '/' + refIndicator.id,
      {
        updated_at: Timestamp.now(),
        area_id: data.areas.filter((obj) => obj.name === area)[0].id,
        area_name: area,
        index: index,
        description: indicator,
        scores: [
          {
            key: 'Initial',
            description: initial,
            value: 1
          },
          {
            key: 'Repeatable',
            description: repeatable,
            value: 2
          },
          {
            key: 'Defined',
            description: defined,
            value: 3
          },
          {
            key: 'Managed',
            description: managed,
            value: 4
          },
          {
            key: 'Optimized',
            description: optimized,
            value: 5
          }
        ],
        is_published: false
      },
      true
    ).then(
      () => {
        setIsLoading(false);
        history.push('/indicator', 'modified');
      },
      (err) => {
        setIsLoading(false);
        toast.current.show({
          severity: 'error',
          summary: 'Gagal mengubah Indikator!\n' + err.message,
          life: 3000
        });
      }
    );
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === 'area') {
      setArea(value);
    } else if (name === 'index') {
      setIndex(value);
    } else if (name === 'indicator') {
      setIndicator(value);
    } else if (name === 'initial') {
      setInitial(value);
    } else if (name === 'repeatable') {
      setRepeatable(value);
    } else if (name === 'defined') {
      setDefined(value);
    } else if (name === 'managed') {
      setManaged(value);
    } else if (name === 'optimized') {
      setOptimized(value);
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    refIndicator ? modifyIndicator() : addIndicator();
  };

  const renderTitle = () => {
    let title = 'Tambah Indikator Baru';
    if (history.location.pathname.includes('/edit')) title = 'Edit Indikator';
    return <h4 className="font-bold mb-4">{title}</h4>;
  };

  return (
    <AppMenu>
      <div className="card">
        {renderTitle()}
        <Divider />
        <div className="grid align-items-center my-2">
          <div className="col-12 lg:col-2 md:col-3">
            <label htmlFor="area" className="font-medium">
              Area
            </label>
          </div>
          <div className="col-12 md:col-5">
            <Dropdown
              name="area"
              id="area"
              value={area}
              options={options}
              valueTemplate={refIndicator?.area_name}
              placeholder="Pilih Area"
              className="w-full"
              onChange={handleChangeInput}
            />
          </div>
        </div>
        <div className="grid align-items-center mb-2">
          <div className="col-12 lg:col-2 md:col-3">
            <label htmlFor="index" className="block font-medium">
              Kode Indikator
            </label>
          </div>
          <div className="col-12 md:col-5">
            <InputText
              type="text"
              name="index"
              id="index"
              value={index}
              placeholder="Masukkan kode indikator"
              className="w-full"
              onChange={handleChangeInput}
              autoFocus
            />
          </div>
        </div>
        <div className="grid mb-2">
          <div className="col-12 lg:col-2 md:col-3">
            <label htmlFor="indicator" className="block md:mt-2 font-medium">
              Deskripsi Indikator
            </label>
          </div>
          <div className="col-12 md:col-5">
            <InputTextarea
              name="indicator"
              id="indicator"
              value={indicator}
              placeholder="Masukkan deskripsi indikator"
              rows={6}
              className="w-full mb-3"
              onChange={handleChangeInput}
            />
          </div>
        </div>

        <div className="form-card my-4">
          <div className="grid mt-0">
            <div className="col-12 lg:col-2 md:col-3">
              <div className="form-card-border">
                <p className="text-center font-semibold">Tingkat</p>
              </div>
            </div>
            <div className="col-12 lg:col-10 md:col-9">
              <div className="form-card-border">
                <p className="text-center font-semibold">Deskripsi Tingkat Indikator</p>
              </div>
            </div>
          </div>
          <Divider />

          <div className="grid align-items-center mt-0">
            <div className="col-12 lg:col-2 md:col-3">
              <div className="form-card-border">
                <label htmlFor="initial" className="block text-center">
                  1 - Initial
                </label>
              </div>
            </div>
            <div className="col-12 lg:col-10 md:col-9">
              <InputTextarea
                name="initial"
                id="initial"
                value={initial}
                placeholder="Masukkan deskripsi tingkat indikator"
                rows={3}
                className="w-full"
                onChange={handleChangeInput}
              />
            </div>
          </div>
          <Divider />

          <div className="grid align-items-center mt-0">
            <div className="col-12 lg:col-2 md:col-3">
              <div className="form-card-border">
                <label htmlFor="repeatable" className="block text-center">
                  2 - Repeatable
                </label>
              </div>
            </div>
            <div className="col-12 lg:col-10 md:col-9">
              <InputTextarea
                name="repeatable"
                id="repeatable"
                value={repeatable}
                placeholder="Masukkan deskripsi tingkat indikator"
                rows={3}
                className="w-full"
                onChange={handleChangeInput}
              />
            </div>
          </div>
          <Divider />

          <div className="grid align-items-center mt-0">
            <div className="col-12 lg:col-2 md:col-3">
              <div className="form-card-border">
                <label htmlFor="defined" className="block text-center">
                  3 - Defined
                </label>
              </div>
            </div>
            <div className="col-12 lg:col-10 md:col-9">
              <InputTextarea
                name="defined"
                id="defined"
                value={defined}
                placeholder="Masukkan deskripsi tingkat indikator"
                rows={3}
                className="w-full"
                onChange={handleChangeInput}
              />
            </div>
          </div>
          <Divider />

          <div className="grid align-items-center mt-0">
            <div className="col-12 lg:col-2 md:col-3">
              <div className="form-card-border">
                <label htmlFor="managed" className="block text-center">
                  4 - Managed
                </label>
              </div>
            </div>
            <div className="col-12 lg:col-10 md:col-9">
              <InputTextarea
                name="managed"
                id="managed"
                value={managed}
                placeholder="Masukkan deskripsi tingkat indikator"
                rows={3}
                className="w-full"
                onChange={handleChangeInput}
              />
            </div>
          </div>
          <Divider />

          <div className="grid align-items-center mt-0">
            <div className="col-12 lg:col-2 md:col-3">
              <div className="form-card-border">
                <label htmlFor="optimized" className="block text-center">
                  5 - Optimized
                </label>
              </div>
            </div>
            <div className="col-12 lg:col-10 md:col-9">
              <InputTextarea
                name="optimized"
                id="optimized"
                value={optimized}
                placeholder="Masukkan deskripsi tingkat indikator"
                rows={3}
                className="w-full"
                onChange={handleChangeInput}
              />
            </div>
          </div>
        </div>

        <div className="flex align-items-center justify-content-end mt-3">
          <Button
            type="button"
            label="Batal"
            onClick={() => history.push('/indicator')}
            icon="pi pi-times"
            className="p-button-warning mr-3"
          />
          <Button
            type="submit"
            label="Simpan"
            icon="pi pi-save"
            loading={isLoading}
            onClick={handleSubmit}
            disabled={
              !area ||
              !index ||
              !indicator ||
              !initial ||
              !repeatable ||
              !defined ||
              !managed ||
              !optimized
            }
          />
        </div>
      </div>
      <Toast ref={toast} style={{ zIndex: 999 }} />
    </AppMenu>
  );
};

export default IndicatorForm;
