import React, { useState } from 'react';
import { FirebaseService } from '../../service/FirebaseService';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import AlthaLogo from '../../assets/images/altha.png';
import { setAccessToken } from '../../utils/creds';

const Login = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setErrorMessage('');
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleSubmit = () => {
    if (email === null || email.length < 4 || password === null || password.length < 4) {
      setErrorMessage('Please fill out email and password.');
      return;
    }

    setIsLoadingSubmission(true);
    FirebaseService.login(email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        user.getIdToken().then((token) => {
          setAccessToken(token);
          setTimeout(() => {
            window.location.replace('/');
          }, 75);
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        let errorMessage = '';
        setIsLoadingSubmission(false);

        if (errorCode === 'auth/network-request-failed') {
          errorMessage = 'No Internet Connection!';
        } else {
          errorMessage = 'Email/Password mismatch!';
        }
        setErrorMessage(errorMessage);
      });
  };

  const renderError = <p className="font-small p-error">{errorMessage}</p>;

  return (
    <div className="layout-main-container">
      <div className="surface-card px-4 md:px-6 pt-7 pb-8 m-auto shadow-2 border-round w-full lg:w-5">
        <div className="text-center">
          <img src={AlthaLogo} alt="Altha Consulting" height={50} className="mb-4" />
          <div className="text-2xl font-medium mb-6">Welcome Back</div>
        </div>

        <>
          <label htmlFor="email" className="block font-medium mb-2">
            Email
          </label>
          <InputText
            type="text"
            name="email"
            id="email"
            placeholder="Masukkan email"
            className={'w-full mb-4' + (errorMessage !== '' ? ' p-invalid' : '')}
            onChange={handleChangeInput}
          />

          <label htmlFor="password" className="block font-medium mb-2">
            Password
          </label>
          <div className="p-inputgroup mb-1">
            <InputText
              type={showPassword ? 'text' : 'password'}
              name="password"
              id="password"
              placeholder="Masukkan password"
              className={'w-full' + (errorMessage !== '' ? ' p-invalid ' : '')}
              onChange={handleChangeInput}
            />
            <span className="p-inputgroup-addon p-0 bg-transparent">
              <Button
                icon={showPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}
                className="p-button-text p-0 bg-transparent"
                style={{ boxShadow: 'none' }}
                onClick={() => setShowPassword(!showPassword)}
              />
            </span>
          </div>
          {renderError}
          <Button
            type="submit"
            label="Sign In"
            loading={isLoadingSubmission}
            className="w-full mt-5"
            onClick={handleSubmit}
          />
        </>
      </div>
    </div>
  );
};

export default Login;
