import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { getAccessToken } from './utils/creds';
import { routes } from './routes';
import { DataContext } from './hooks/data';

const App = () => {
    return (
        <DataContext>
            <BrowserRouter>
                <Switch>
                    {routes.map((route, index) => (
                        <Route key={index} exact path={route.path}>
                            {route.element}
                            {getAccessToken() === null ? <Redirect to='/login' /> : null}
                        </Route>
                    ))}
                </Switch>
            </BrowserRouter>
        </DataContext>
    );
};

export default App;
