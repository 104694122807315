import React, { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';

const Table = (props) => {
  const { hideGrid, striped, headerColumnGroup, data, loading, totalRows = 10, children } = props;

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(totalRows);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const template = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 100, value: 100 }
      ];

      return (
        <>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Items per page:{' '}
          </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: 'var(--text-color)',
            userSelect: 'none',
            width: '120px',
            textAlign: 'center'
          }}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    }
  };

  return (
    <DataTable
      showGridlines={!hideGrid}
      stripedRows={striped}
      headerColumnGroup={headerColumnGroup}
      value={data}
      loading={loading}
      className="line-height-3"
      emptyMessage="Tidak ada data"
      scrollable
      scrollDirection="horizontal"
      paginator
      paginatorTemplate={template}
      first={first}
      rows={rows}
      onPage={onPageChange}
      paginatorClassName="justify-content-end"
      responsiveLayout="scroll">
      {children}
    </DataTable>
  );
};

export default Table;
