import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';

const SidebarSubmenu = (props) => {
  const { onMenuItemClick, items, root, className } = props;
  const [activeIndex, setActiveIndex] = useState(null);

  const handleMenuItemClick = (event, item, index) => {
    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    //execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    if (index === activeIndex) setActiveIndex(null);
    else setActiveIndex(index);

    if (onMenuItemClick) {
      onMenuItemClick({
        originalEvent: event,
        item: item
      });
    }
  };

  const onKeyDown = (event) => {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.preventDefault();
      event.target.click();
    }
  };

  const renderLinkContent = (item) => {
    const submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon" />;
    const badge = item.badge && <Badge value={item.badge} />;

    return (
      <>
        <i className={item.icon} />
        <span>{item.label}</span>
        {submenuIcon}
        {badge}
        <Ripple />
      </>
    );
  };

  const renderLink = (item, i) => {
    const content = renderLinkContent(item);

    if (item.to) {
      return (
        <NavLink
          aria-label={item.label}
          onKeyDown={onKeyDown}
          role="menuitem"
          className="p-ripple"
          activeClassName="router-link-active router-link-exact-active"
          to={item.to}
          onClick={(e) => handleMenuItemClick(e, item, i)}
          target={item.target}>
          {content}
        </NavLink>
      );
    }
    return (
      <a
        tabIndex="0"
        aria-label={item.label}
        onKeyDown={onKeyDown}
        role="menuitem"
        href={item.url}
        className="p-ripple"
        onClick={(e) => handleMenuItemClick(e, item, i)}
        target={item.target}>
        {content}
      </a>
    );
  };

  const newItems =
    items &&
    items.map((item, i) => {
      const active = activeIndex === i;
      const styleClass = classNames(item.badgeStyleClass, {
        'layout-menuitem-category': root,
        'active-menuitem': active && !item.to
      });

      if (root) {
        return (
          <li className={styleClass} key={i} role="none">
            {root === true && (
              <>
                <div className="layout-menuitem-root-text" aria-label={item.label}>
                  {item.label}
                </div>
                <SidebarSubmenu items={item.items} onMenuItemClick={onMenuItemClick} />
              </>
            )}
          </li>
        );
      }
      return (
        <li className={styleClass} key={i} role="none">
          {renderLink(item, i)}
          <CSSTransition
            classNames="layout-submenu-wrapper"
            timeout={{ enter: 1000, exit: 450 }}
            in={active}
            unmountOnExit>
            <SidebarSubmenu items={item.items} onMenuItemClick={onMenuItemClick} />
          </CSSTransition>
        </li>
      );
    });

  return newItems !== undefined && newItems !== null ? (
    <ul className={className} role="menu">
      {newItems}
    </ul>
  ) : null;
};

export const Sidebar = (props) => (
  <div className="layout-menu-container">
    <SidebarSubmenu
      items={props.model}
      className="layout-menu"
      onMenuItemClick={props.onMenuItemClick}
      root={true}
      role="menu"
    />
  </div>
);
