import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useData } from '../../hooks/data';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import AppMenu from '../../components/Menu/AppMenu';
import Table from '../../components/Table/Table';
import TableActionButton from '../../components/Table/TableActionButton';
import ProjectForm from './ProjectForm';
import { tagSeverity } from '../../utils/utils';
import { FeatureFlag } from '../../utils/env';
import { FirebaseService } from '../../service/FirebaseService';
import { getApp, initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { setAccessToken } from '../../utils/creds';
import { getUserFromData} from '../../utils/utils';

FirebaseService.initFirebase();

const Project = () => {
  const toast = useRef(null);
  const history = useHistory();
  const data = useData();
  const auth = getAuth();

  const [projects, setProjects] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [email, setEmail] = useState([]);
  const [add, setAdd] = useState([]);
  const [role, setRole] = useState([]);

  useEffect(() => {
    if (data.projectListenerError !== '') {
      handleToast('error', 'Gagal mengambil data project! ' + data.projectListenerError);
    } else if (data.projects.length > 0) {
      parseProjects();
      // userAuth();
    }
  }, [data]);

  const userAuth = () => {
    onAuthStateChanged(auth, (user)=> {
      if(user){
        // console.log(user.email);
        let add = user.email;
        setAdd(add);

        const email = user.email;
        setEmail(email);
      }

    })
  };

  const parseProjects = () => {
    onAuthStateChanged(auth, (user)=> {
      if(user){
        let userRef = data.user.filter((userObj) => userObj.email === user.email);
        userRef.map((userObj) => {
        const roles = userObj.role;
        setRole(roles);
        if(userObj.role === 'Admin'){
          let refs = data.projects;
          refs.map((obj) => {
            let refDate = obj.period.toDate();
            obj.period_string = refDate.toLocaleDateString('id-ID', { month: 'long', year: 'numeric' });
            const progress = obj.areas.filter((area) => area.score_value > 0).length;
            obj.progress = `${progress}/${obj.areas.length}`;
          });
          refs.sort((a, b) => b.created_at - a.created_at);
          setProjects(refs);

        }else{
          let refs = data.projects.filter((projectObj) => projectObj.createdBy === user.email);
           refs.map((obj) => {
            let refDate = obj.period.toDate();
            obj.period_string = refDate.toLocaleDateString('id-ID', { month: 'long', year: 'numeric' });
            const progress = obj.areas.filter((area) => area.score_value > 0).length;
            obj.progress = `${progress}/${obj.areas.length}`;
          });
          refs.sort((a, b) => b.created_at - a.created_at);
          setProjects(refs);
        }
      });
      }
    })
  };

  const handleToast = (type, text) => {
    toast.current.show({ severity: type, summary: text, life: 3000 });
  };

  const statusBody = (rowData) => (
    <Tag
      severity={tagSeverity(rowData.status_code)}
      className="uppercase px-2"
      value={rowData.status.replace('-', ' ')}
      style={{ letterSpacing: 0.3, fontSize: 12 }}
    />
  );

  const actionBody = (rowData) => (
    <div className="flex align-items-center">
      <TableActionButton
        className="bg-blue-800 border-blue-800"
        icon="pi pi-check-circle"
        tooltipText="Target"
        onClick={() => history.push(`/project/${rowData.id}/target`)}
      />
      <TableActionButton
        className={classNames({
          'p-disabled': rowData.status_code === 'open' || rowData.status === 'Pengisian Target'
        })}
        color="warning"
        icon="pi pi-star-fill"
        tooltipText="Penilaian"
        onClick={() => history.push(`/project/${rowData.id}/assessment`)}
      />
      <TableActionButton
        className={classNames({
          'p-disabled': rowData.status_code !== 'completed' || rowData.status === 'Pengisian Target'
        })}
        color="success"
        icon="pi pi-chart-line"
        tooltipText="Dashboard"
        onClick={() => history.push(`/project/${rowData.id}/assessment/dashboard`, rowData)}
      />
    </div>
  );

  return (
    <AppMenu>
      <div className="card">
        <div className="flex align-items-center justify-content-between mb-4">
          <h3 className="font-bold mb-0">Engagement</h3>
          {role === "Admin" ? (
            <Button label="Tambah Engagement" icon="pi pi-plus" onClick={() => setShowForm(true)} />
          ) : (
            <div />
          )}
        </div>
        <Table data={projects} loading={data.projectIsLoading}>
          <Column field="code" header="Kode Engagement" style={{ minWidth: '15%' }} />
          <Column field="entity" header="Entitas" style={{ minWidth: '30%' }} />
          <Column field="period_string" header="Periode Penilaian" style={{ minWidth: '15%' }} />
          <Column field="progress" header="Progress" style={{ minWidth: '10%' }} />
          <Column
            field="status_code"
            header="Status"
            body={statusBody}
            style={{ minWidth: '18%' }}
          />
          <Column header="Action" body={actionBody} style={{ minWidth: '12%' }} />
        </Table>
      </div>

      <ProjectForm
        areas={data.areas}
        indicators={data.indicators}
        show={showForm}
        setShow={() => setShowForm(false)}
        setSuccess={() => handleToast('success', 'Berhasil menambahkan Engagements')}
        setFailed={(message) => handleToast('err', message)}
      />
      <Toast ref={toast} />
    </AppMenu>
  );
};

export default Project;
