import React, { useEffect, useState } from 'react';
import { FirebaseService } from '../../service/FirebaseService';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Timestamp } from 'firebase/firestore';
import { keys } from '../../utils/keys';

const AreaForm = (props) => {
  const { data, show, setShow, setSuccess, setFailed } = props;
  const [index, setIndex] = useState('');
  const [area, setArea] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data === undefined || data === null) {
      return;
    }
    if (data.index !== null) {
      setIndex(data.index);
      setArea(data.name);
      setDescription(data.description);
    }
  }, [data]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === 'index') {
      setIndex(value);
    } else if (name === 'area') {
      setArea(value);
    } else if (name === 'description') {
      setDescription(value);
    }
  };

  const handleClose = () => {
    setIndex('');
    setArea('');
    setDescription('');
    setShow();
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const now = Timestamp.now();

    if (data.index !== undefined && data.index !== null) {
      modifyAreaHandler(now);
    } else {
      addAreaHandler(now);
    }
  };

  const modifyAreaHandler = (t) => {
    FirebaseService.updateDocument(keys.AREAS_COLLECTION + '/' + data.id, {
      updated_at: t,
      index: index,
      name: area,
      description: description
    }).then(
      () => {
        setIsLoading(false);
        setSuccess('Berhasil mengubah data Area!');
        handleClose();
      },
      (e) => {
        setIsLoading(false);
        setFailed('Gagal mengubah data Area! ', e);
      }
    );
  };

  const addAreaHandler = (t) => {
    FirebaseService.addDocument(keys.AREAS_COLLECTION, {
      created_at: t,
      updated_at: t,
      index: index,
      name: area,
      description: description,
      order_id: data.order_id
    }).then(
      () => {
        setIsLoading(false);
        setSuccess('Berhasil menambahkan data Area!');
        handleClose();
      },
      (e) => {
        setIsLoading(false);
        setFailed('Gagal menambahkan data Area!', e);
      }
    );
  };

  const footer = (
    <div className="flex align-items-center justify-content-end">
      <Button
        type="button"
        label="Batal"
        onClick={handleClose}
        icon="pi pi-times"
        className="p-button-warning"
      />
      <Button
        type="submit"
        label="Simpan"
        icon="pi pi-save"
        onClick={handleSubmit}
        loading={isLoading === true}
        disabled={!index || !area || !description}
      />
    </div>
  );

  return (
    <Dialog
      header="Tambah Area Baru"
      visible={show}
      style={{ width: '50vw' }}
      modal
      footer={footer}
      onHide={handleClose}>
      <div className="grid">
        <div className="col-3">
          <label htmlFor="index" className="block text-900 font-medium mb-2">
            Kode Area
          </label>
          <InputText
            type="text"
            name="index"
            id="index"
            value={index || ''}
            placeholder="Masukkan kode area"
            className="w-full mb-3"
            onChange={handleChangeInput}
            autoFocus
          />
        </div>
        <div className="col-9">
          <label htmlFor="area" className="block text-900 font-medium mb-2">
            Area
          </label>
          <InputText
            type="text"
            name="area"
            id="area"
            value={area || ''}
            placeholder="Masukkan area"
            className="w-full mb-3"
            onChange={handleChangeInput}
          />
        </div>
      </div>
      <label htmlFor="description" className="block text-900 font-medium mb-2">
        Deskripsi Area Evaluasi
      </label>
      <InputTextarea
        name="description"
        id="description"
        value={description || ''}
        placeholder="Masukkan deskripsi area evaluasi"
        rows={5}
        className="w-full"
        onChange={handleChangeInput}
      />
    </Dialog>
  );
};

export default AreaForm;
